import Box from '@mui/material/Box';
import { IconButton } from '@mui/material';
import { useContext, useState } from 'react';
import { ThemeContext } from 'theme/ThemeProvider';
import { useTranslation } from 'react-i18next';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

const DarkModeSwitch = () => {
  const { t }: { t: any } = useTranslation();
  const setThemeName = useContext(ThemeContext);
  const curThemeName = localStorage.getItem('appTheme') || 'PureLightTheme';
  const [theme, setTheme] = useState(curThemeName);

  const toggleTheme = () => {
    if (curThemeName === 'PureLightTheme') {
      setTheme('NebulaFighterTheme');
      setThemeName('NebulaFighterTheme');
    } else {
      setTheme('PureLightTheme');
      setThemeName('PureLightTheme');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'text.primary',
        borderRadius: 1,
        p: 3
      }}
    >
      {theme === 'NebulaFighterTheme' ? t('Dark mode') : t('Light mode')}
      <IconButton sx={{ ml: 1 }} onClick={toggleTheme} color="inherit">
        {theme === 'NebulaFighterTheme' ? <Brightness7Icon /> : <Brightness4Icon />}
      </IconButton>
    </Box>
  );
};

export default DarkModeSwitch;
