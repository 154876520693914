import { lazy, Suspense } from 'react';

import SuspenseLoader from 'components/core/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const ApplicationList = Loader(lazy(() => import('pages/features/applications')));
const ApplicationViewer = Loader(lazy(() => import('pages/features/applications/single')));

const applicationRoutes = [
  {
    path: '',
    element: <ApplicationList />
  },
  {
    path: 'view/:applicationId',
    element: <ApplicationViewer />
  },
  {
    path: 'position/:positionId',
    element: <ApplicationList />
  }
];

export default applicationRoutes;
