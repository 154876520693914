import Scrollbar from 'components/layout/Scrollbar';
import { SidebarContent, SidebarWrapper } from 'pages/util/Auth/Login/Login.styles';
import { Box } from '@mui/material';
import DemoText from 'pages/util/Auth/Login/DemoText';

const DemoSidebar = () => {
  return (
    <SidebarWrapper
      sx={{
        display: { xs: 'none', md: 'flex' }
      }}
    >
      <Scrollbar>
        <SidebarContent>
          <Box mt={6}>
            <DemoText />
          </Box>
        </SidebarContent>
      </Scrollbar>
    </SidebarWrapper>
  );
};

export default DemoSidebar;
