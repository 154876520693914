import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import 'nprogress/nprogress.css';
import App from 'App';
import { SidebarProvider } from 'contexts/SidebarContext';
import * as serviceWorker from 'serviceWorker';
import { AuthProvider } from 'contexts/AmplifyContext';
import { EnvironmentProvider } from 'contexts/EnvironmentContext';

ReactDOM.render(
  <EnvironmentProvider>
    <HelmetProvider>
      <SidebarProvider>
        <BrowserRouter>
          <AuthProvider>
            <App />
          </AuthProvider>
        </BrowserRouter>
      </SidebarProvider>
    </HelmetProvider>
  </EnvironmentProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
