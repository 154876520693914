import { forwardRef, ReactElement, Ref, useRef, useState } from 'react';
import useAuth from 'hooks/useAuth';
import { useNavigate } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  ListItemText,
  MenuItem,
  Popover,
  Slide,
  TextField,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { editUserAsSelf, updatePassword } from 'api/internal/userManagement';
import { errorNotification, successNotification } from 'helpers/libraries/snackbar';
import { useSnackbar } from 'notistack';
import {
  ButtonPrimary,
  DialogWrapper,
  MenuListWrapperPrimary,
  MenuUserBox,
  UserAvatar,
  UserBoxButton,
  UserBoxDescription,
  UserBoxLabel,
  UserBoxText
} from './index.styles';
import { TransitionProps } from '@mui/material/transitions';
import { UserEditRequest, UserUpdatePasswordRequest } from 'types/shared/api/userManagement';
import ImageUpload from 'components/forms/ImageUpload';

export const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function HeaderUserbox() {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [openEditUser, setOpenEditUser] = useState(false);

  const { t }: { t: any } = useTranslation();
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const ref = useRef<any>(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
    }
  };

  const handleResetUserPassword = async (values: any) => {
    try {
      const resetUserPasswordRequest: UserUpdatePasswordRequest = {
        password: values.password,
        email: user.email,
        // Temporary password only
        permanent: true
      };
      const ok = await updatePassword(resetUserPasswordRequest);
      if (!ok) {
        throw new Error('Failed to create user');
      }
      setOpenChangePassword(false);
      successNotification(enqueueSnackbar, t('Password has been updated successfully'));
    } catch (e) {
      errorNotification(enqueueSnackbar, t('Failed to update user password'));
    }
  };

  const handleEditUser = async (values: any) => {
    try {
      const userEditRequest: UserEditRequest = {
        existingEmail: user.email,
        picture: values.picture,
        name: values.name
      };
      const ok = await editUserAsSelf(userEditRequest);
      if (!ok) {
        throw new Error('Failed to edit user');
      }
      setOpenEditUser(false);
      successNotification(
        enqueueSnackbar,
        t(
          'Your details have been updated successfully. You will need to log out and then back in to see these changes.'
        )
      );
    } catch (e) {
      errorNotification(enqueueSnackbar, t('Failed to update your details'));
    }
  };

  return (
    <>
      <UserBoxButton color="primary" ref={ref} onClick={handleOpen}>
        <UserAvatar alt={user.name} src={user.picture} />
      </UserBoxButton>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox
          sx={{
            minWidth: 210
          }}
          display="flex"
        >
          <Avatar variant="rounded" alt={user.name} src={user.picture} />
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">{user.email}</UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider
          sx={{
            mb: 0
          }}
        />
        <MenuListWrapperPrimary disablePadding>
          <MenuItem>
            <ListItemText
              primaryTypographyProps={{
                variant: 'h5'
              }}
              primary={t('Change your details')}
              onClick={() => {
                setOpen(false);
                setOpenEditUser(true);
              }}
            />
          </MenuItem>
          <MenuItem>
            <ListItemText
              primaryTypographyProps={{
                variant: 'h5'
              }}
              primary={t('Change password')}
              onClick={() => {
                setOpen(false);
                setOpenChangePassword(true);
              }}
            />
          </MenuItem>
        </MenuListWrapperPrimary>
        <Divider />
        <Box m={1}>
          <Button color="primary" fullWidth onClick={handleLogout}>
            <LockOpenTwoToneIcon
              sx={{
                mr: 1
              }}
            />
            {t('Sign out')}
          </Button>
        </Box>
      </Popover>

      {/* TODO: Figure out how to pull into own component */}
      <DialogWrapper
        open={openChangePassword}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenChangePassword(false)}
      >
        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
          <Typography align="center" sx={{ pt: 8, px: 6, pb: 2 }} variant="h3">
            {t('Set your new password')}
          </Typography>
        </Box>

        <Formik
          initialValues={{
            password: '',
            password_confirm: '',
            submit: null
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string().min(8).max(255).required(t('The password field is required')),
            password_confirm: Yup.string().oneOf([Yup.ref('password')], t('Both password fields need to be the same'))
          })}
          onSubmit={async (_values, { setErrors }) => {
            try {
              await handleResetUserPassword(_values);
            } catch (err) {
              setErrors({ submit: err.message });
            }
          }}
        >
          {({ handleBlur, handleChange, handleSubmit, isSubmitting, errors, touched, values }) => (
            <form onSubmit={handleSubmit}>
              <Box p={2}>
                <Grid container spacing={2} p={4}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      type="password"
                      error={Boolean(touched.password && errors.password)}
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label={t('Password')}
                      placeholder={t('Write a password here...')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      type="password"
                      error={Boolean(touched.password_confirm && errors.password_confirm)}
                      name="password_confirm"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label={t('Confirm password')}
                      placeholder={t('Confirm password here...')}
                    />
                  </Grid>
                </Grid>

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    pb: 2
                  }}
                >
                  <Button variant="text" size="large" sx={{ mx: 1 }} onClick={() => setOpenChangePassword(false)}>
                    {t('Cancel')}
                  </Button>
                  <ButtonPrimary
                    type="submit"
                    startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
                    disabled={Boolean(errors.submit) || isSubmitting}
                    size="large"
                    sx={{ mx: 1, px: 3 }}
                    variant="contained"
                  >
                    {t('Reset password')}
                  </ButtonPrimary>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </DialogWrapper>

      {/*Edit user dialog*/}
      {openEditUser && (
        <DialogWrapper
          open={openEditUser}
          maxWidth="sm"
          fullWidth
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setOpenEditUser(false)}
        >
          <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
            <Typography align="center" sx={{ pt: 8, px: 6, pb: 2 }} variant="h3">
              {t('Change your details')}
            </Typography>
          </Box>

          <Formik
            initialValues={{
              picture: user.picture,
              name: user.name,
              submit: null
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().max(255).required(t('The name field is required')),
              picture: Yup.string().max(255).required(t('The picture field is required'))
            })}
            onSubmit={async (_values, { setErrors }) => {
              try {
                await handleEditUser(_values);
              } catch (err) {
                setErrors({ submit: err.message });
              }
            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                <Box p={2}>
                  <Grid container spacing={2} p={4}>
                    <Grid item xs={6} sx={{ marginLeft: 'auto', marginRight: 'auto' }}>
                      <Typography variant="caption">User picture</Typography>
                      <ImageUpload
                        onUpdate={(filePath) => setFieldValue('picture', filePath)}
                        filePath={values.picture}
                      ></ImageUpload>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        error={Boolean(touched.name && errors.name)}
                        fullWidth
                        helperText={touched.name && errors.name}
                        label={t('Name')}
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      pb: 2
                    }}
                  >
                    <Button color="secondary" onClick={() => setOpenEditUser(false)}>
                      {t('Cancel')}
                    </Button>
                    <Button
                      type="submit"
                      startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
                      disabled={Boolean(errors.submit) || isSubmitting}
                      variant="contained"
                    >
                      {t('Confirm')}
                    </Button>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </DialogWrapper>
      )}
    </>
  );
}

export default HeaderUserbox;
