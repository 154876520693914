import { useContext } from 'react';
import Scrollbar from 'components/layout/Scrollbar';
import { SidebarContext } from 'contexts/SidebarContext';

import { Box, Drawer, alpha, styled, Divider, useTheme, lighten, darken } from '@mui/material';

import SidebarTopSection from './SidebarTopSection';
import SidebarMenu from './SidebarMenu';
import SidebarFooter from './SidebarFooter';
import LogoSign from 'components/common/LogoSign';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: 7;
        height: 100%;
        padding-bottom: 61px;
`
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();

  const sidebarContent = (
    <>
      <Scrollbar>
        <Box mt={3}>
          <Box sx={{ display: 'flex' }} mx={2}>
            <LogoSign />
          </Box>
        </Box>
        <Divider
          sx={{
            my: theme.spacing(3),
            mx: theme.spacing(2),
            background: theme.colors.alpha.trueWhite[10]
          }}
        />
        <SidebarTopSection />
        <Divider
          sx={{
            my: theme.spacing(3),
            mx: theme.spacing(2),
            background: theme.colors.alpha.trueWhite[10]
          }}
        />
        <SidebarMenu />
      </Scrollbar>
      <Divider
        sx={{
          background: theme.colors.alpha.trueWhite[10]
        }}
      />
      <SidebarFooter />
    </>
  );

  return (
    <>
      {/* Fixed content - only appears on larger screens */}
      <SidebarWrapper
        sx={{
          display: {
            xs: 'none',
            lg: 'inline-block'
          },
          position: 'fixed',
          left: 0,
          top: 0,
          background:
            theme.palette.mode === 'dark'
              ? alpha(lighten(theme.header.background, 0.1), 0.5)
              : darken(theme.colors.alpha.black[100], 0.5),
          boxShadow: theme.palette.mode === 'dark' ? theme.sidebar.boxShadow : 'none'
        }}
      >
        {sidebarContent}
      </SidebarWrapper>

      {/* Drawer content - only appears in small mode, and appears as a drawer */}
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper
          sx={{
            background:
              theme.palette.mode === 'dark' ? theme.colors.alpha.white[100] : darken(theme.colors.alpha.black[100], 0.5)
          }}
        >
          {sidebarContent}
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;
