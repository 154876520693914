import {
  Box,
  styled,
} from '@mui/material';

const LogoSignWrapper = styled(Box)(
  () => `
        height: 38px;
        padding-right: 15px;
`
);

const LogoText = styled('p')<{smallText: boolean}>(
  ({smallText}) => `
    font-weight: bold;
    font-weight: bold;
    margin-top: ${smallText ? '22px' : '0px'};
    font-size: ${smallText ? '19px': '36px'};
    line-height: 36px;
  `
);

const LogoSection = styled('div')`
  margin-left: auto;
  margin-right: auto;
`;

export interface LogoProps {
  small?: boolean;
}

function Logo (props: LogoProps) {
  const { small } = props;

  return (
    <LogoSection>
      <LogoSignWrapper>
        <LogoText smallText={small}>PRISMA</LogoText>
      </LogoSignWrapper>
    </LogoSection>
  );
}

export default Logo;
