import { OptionsObject, SnackbarMessage } from 'notistack';
import { Zoom } from '@mui/material';

const defaultConfig: Partial<OptionsObject> = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right'
  },
  TransitionComponent: Zoom
}

export const successNotification = (handler: (message: SnackbarMessage, options: OptionsObject) => void,
                                    message: string) => {
  handler(message, {
    ...defaultConfig,
    variant: 'success',
  });
}

export const infoNotification = (handler: (message: SnackbarMessage, options: OptionsObject) => void,
                                 message: string) => {
  handler(message, {
    ...defaultConfig,
    variant: 'info',
  });
}

export const warningNotification = (handler: (message: SnackbarMessage, options: OptionsObject) => void,
                                    message: string) => {
  handler(message, {
    ...defaultConfig,
    variant: 'warning',
  });
}

export const errorNotification = (handler: (message: SnackbarMessage, options: OptionsObject) => void,
                                  message: string) => {
  handler(message, {
    ...defaultConfig,
    variant: 'error',
  });
}