import { Box, styled } from '@mui/material';
import { useContext } from 'react';
import { EnvironmentContext } from 'contexts/EnvironmentContext';

const PrismaText = styled('p')(
  (theme) => `
    font-size: 2.4rem;
    text-align: center;
    font-weight: bold;
    margin-bottom: 0;
  `
);

const NotActiveText = styled('p')(
  (theme) => `
    font-size: 1rem;
    margin-top: 0;
  `
);

const AppInit = () => {
  const { tenantActive, billingStatus } = useContext(EnvironmentContext);

  return (
    <Box
      sx={{
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        width: '100%',
        height: '100%'
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <div>
        <PrismaText>PRISMA</PrismaText>
        {(!tenantActive || billingStatus === 'SUSPENDED') && (
          <NotActiveText>This site is no longer active</NotActiveText>
        )}
      </div>
    </Box>
  );
};

export default AppInit;
