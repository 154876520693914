import * as Yup from 'yup';
import { Formik } from 'formik';
import { Link as RouterLink } from 'react-router-dom';

import {
  Box,
  Button,
  TextField,
  Link,
  CircularProgress, Typography
} from '@mui/material';
import useAuth from 'hooks/useAuth';
import useRefMounted from 'hooks/useRefMounted';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { LoginAttempt } from 'contexts/AmplifyContext';
import { useSnackbar } from 'notistack';
import { errorNotification } from 'helpers/libraries/snackbar';

const Form = () => {
  const isMountedRef = useRefMounted();
  const { login, setUserPasswordAfterTempLogin } = useAuth() as any;
  const { enqueueSnackbar } = useSnackbar();
  const [userEmail, setUserEmail] = useState<string>(undefined);
  const [loginAttempt, setLoginAttempt] = useState<LoginAttempt>(undefined);
  const isLoginFormMounted = useRefMounted();
  const isLoginResetTempFormMounted = useRefMounted();
  const { t }: { t: any } = useTranslation();

  const handleLogin = async (loginAttempt: any): Promise<void> => {
    if (isMountedRef.current) {
      setLoginAttempt(loginAttempt);
    }
  }

  const cacheEmail = async (email: string): Promise<void> => {
    if (isMountedRef.current) {
      setUserEmail(email);
    }
  }

  return (
    <>
      { loginAttempt && loginAttempt.errorReason === 'NEW_PASSWORD_REQUIRED' && (
        <>
          <Typography>{t('Please set your new password')}</Typography>
          <Formik
            initialValues={{
              password: '',
              password_confirm: '',
              submit: null
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string()
                .max(255)
                .required(t('The password field is required')),
              password_confirm: Yup.string()
                .oneOf(
                  [Yup.ref('password')],
                  t('Both password fields need to be the same')
                )
                .required(t('This field is required'))
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ): Promise<void> => {
              try {
                const result = await setUserPasswordAfterTempLogin(userEmail, values.password);
                await handleLogin(result);

                if (isLoginFormMounted.current) {
                  setStatus({ success: true });
                  setSubmitting(false);
                }
              } catch (err) {
                errorNotification(enqueueSnackbar, err.message);

                if (isLoginFormMounted.current) {
                  setStatus({ success: false });
                  setErrors({ submit: err.message });
                  setSubmitting(false);
                }
              }
            }}
          >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }): JSX.Element => (
              <form onSubmit={handleSubmit}>
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  margin="normal"
                  helperText={touched.password && errors.password}
                  label={t('Password')}
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password }
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password_confirm && errors.password_confirm)}
                  fullWidth
                  margin="normal"
                  helperText={touched.password_confirm && errors.password_confirm}
                  label={t('Confirm password')}
                  name="password_confirm"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password_confirm}
                  variant="outlined"
                />

                <Button
                  sx={{
                    mt: 3
                  }}
                  color="primary"
                  startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
                  disabled={isSubmitting}
                  type="submit"
                  fullWidth
                  size="large"
                  variant="contained"
                >
                  {t('Set new password')}
                </Button>
              </form>
            )}
          </Formik>
        </>
      )}
      { !loginAttempt && (
        <Formik
          initialValues={{
            email: '',
            password: '',
            submit: null
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email(t('The email provided should be a valid email address'))
              .max(255)
              .required(t('The email field is required')),
            password: Yup.string()
              .max(255)
              .required(t('The password field is required')),
            terms: Yup.boolean().oneOf(
              [true],
              t('You must agree to our terms and conditions')
            )
          })}
          onSubmit={async (
            values,
            { setErrors, setStatus, setSubmitting }
          ): Promise<void> => {
            try {
              const result = await login(values.email, values.password);
              await handleLogin(result);
              await cacheEmail(values.email);

              if (isLoginResetTempFormMounted.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err) {
              errorNotification(enqueueSnackbar, err.message);

              if (isLoginResetTempFormMounted.current) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }
          }}
        >
          {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }): JSX.Element => (
            <form noValidate onSubmit={handleSubmit}>
              <TextField
                error={Boolean(touched.email && errors.email)}
                fullWidth
                margin="normal"
                autoFocus
                helperText={touched.email && errors.email}
                label={t('Email address')}
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                margin="normal"
                helperText={touched.password && errors.password}
                label={t('Password')}
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password }
                variant="outlined"
              />
              <Box
                alignItems="center"
                display={{ xs: 'block', md: 'flex' }}
                justifyContent="flex-end"
              >
                <Link component={RouterLink} to="/account/recover-password">
                  <b>{t('Forgot password?')}</b>
                </Link>
              </Box>

              <Button
                sx={{
                  mt: 3
                }}
                color="primary"
                startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
                disabled={isSubmitting}
                type="submit"
                fullWidth
                size="large"
                variant="contained"
              >
                {t('Sign in')}
              </Button>
            </form>
          )}
        </Formik>
      )}
    </>
  );
};

export default Form;
