import { lazy, Suspense } from 'react';

import SuspenseLoader from 'components/core/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Overview = Loader(lazy(() => import('pages/jobSite/Landing')));
const PositionPage = Loader(lazy(() => import('pages/jobSite/PositionListing')));
const PositionsSearch = Loader(lazy(() => import('pages/jobSite/PositionSearch')));
const CustomPage = Loader(lazy(() => import('pages/jobSite/CustomPage')));
const CustomPageList = Loader(lazy(() => import('pages/jobSite/CustomPageList')));
const TeamsList = Loader(lazy(() => import('pages/jobSite/TeamList')));

const jobSiteRoutes = [
  {
    path: '/',
    element: <Overview />
  },
  {
    path: '/positions',
    element: <PositionsSearch />
  },
  {
    path: '/pages',
    element: <CustomPageList />
  },
  {
    path: '/pages/:pageId',
    element: <CustomPage />
  },
  {
    path: '/positions/:positionId',
    element: <PositionPage />
  },
  {
    path: '/teams',
    element: <TeamsList />
  }
];

export default jobSiteRoutes;
