import { useRef, useState } from 'react';
import { Box, IconButton, styled } from '@mui/material';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { uploadPublicFiles } from 'api/internal/fileManager';

const Container = styled(Box)(
  ({ theme }) => ` 
  position: relative;
  width: 100%;
  max-height: 650px;
  cursor: pointer;

  &:hover {
    filter: brightness(0.7);
  }

  &:hover .edit-icon {
    opacity: 1;
    filter: drop-shadow(0px 0px 2px #000);
  }
`
);

const Image = styled('img')(
  ({ theme }) => ` 
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: ${theme.general.borderRadiusLg};
   border: 1px solid ${theme.colors.alpha.black[30]};
`
);

const EditButton = styled(IconButton)(
  ({ theme }) => `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  background-color: ${theme.colors.alpha.white[100]};
  border: 1px solid ${theme.colors.alpha.black[100]};
  
  &:hover {
    background-color: ${theme.colors.alpha.white[100]}; // Override built-in
    opacity: 1;
  }
`
);

const PlaceholderBox = styled(Box)(
  ({ theme }) => `
    min-height: 265px;
    width: 100%;
    border: 1px solid ${theme.colors.alpha.black[30]};
    border-radius: ${theme.general.borderRadiusLg};
  `
);

interface ImageUploadProps {
  filePath?: string;
  onUpdate: (imageSrc: string) => void;
  accept?: string;
}

const ImageUpload = (props: ImageUploadProps) => {
  const [imageSrc, setImageSrc] = useState(props.filePath ?? '');
  const hiddenFileInput = useRef(null);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      let formData = new FormData();
      formData.append('file', event.target.files[0]);
      const { data } = await uploadPublicFiles(formData);
      if (data[0]) {
        setImageSrc(data[0].absolute);
        props.onUpdate(data[0].absolute);
      }
    }
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  return (
    <>
      <Container onClick={handleClick}>
        {imageSrc ? <Image src={imageSrc} alt="Uploaded Image" /> : <PlaceholderBox></PlaceholderBox>}

        <EditButton className="edit-icon">
          <EditTwoToneIcon />
        </EditButton>
        <input
          type="file"
          accept={props.accept ?? 'image/*'}
          id="imageInput"
          ref={hiddenFileInput}
          hidden
          onChange={(event) => handleFileChange(event)}
        />
      </Container>
    </>
  );
};

export default ImageUpload;
