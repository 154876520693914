import { RouteObject } from 'react-router';

import Authenticated from 'components/core/Routes/Authenticated';
import DocsLayout from 'layouts/DocsLayout';
import ExtendedSidebarLayout from 'layouts/SidebarLayout';

import managementRoutes from './management';
import documentationRoutes from './documentation';
import accountRoutes from './account';
import { Navigate, Outlet } from 'react-router-dom';
import helpRoutes from 'router/help';
import developmentRoutes from 'router/development';
import positionsRoutes from 'router/features/positions';
import applicationRoutes from 'router/features/applications';
import hiringWorkflowsRoutes from 'router/features/hiringWorkflows';
import jobSiteRoutes from 'router/jobSite';
import JobSiteLayout from 'layouts/JobSiteLayout';
import statusRoutes from 'router/status';
import BaseLayout from 'layouts/BaseLayout';
import { lazy, Suspense } from 'react';

import SuspenseLoader from 'components/core/SuspenseLoader';
import ApplyLayout from 'layouts/ApplyLayout';
import applyRoutes from 'router/apply/index';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Status404 = Loader(lazy(() => import('pages/util/Status/Status404')));

const router: RouteObject[] = [
  {
    path: 'account',
    children: accountRoutes
  },
  {
    path: '',
    element: <JobSiteLayout />,
    children: jobSiteRoutes
  },
  {
    path: 'status',
    element: <BaseLayout />,
    children: statusRoutes
  },
  {
    path: 'apply',
    element: <ApplyLayout />,
    children: applyRoutes
  },
  {
    path: 'app',
    element: (
      <Authenticated>
        <Outlet />
      </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="dashboard" replace />
      },
      {
        path: 'management',
        children: managementRoutes,
        element: <ExtendedSidebarLayout />
      },
      {
        path: 'positions',
        children: positionsRoutes,
        element: <ExtendedSidebarLayout />
      },
      {
        path: 'applications',
        children: applicationRoutes,
        element: <ExtendedSidebarLayout />
      },
      {
        path: 'hiring-workflows',
        children: hiringWorkflowsRoutes,
        element: <ExtendedSidebarLayout />
      },
      {
        path: 'help',
        children: helpRoutes,
        element: <ExtendedSidebarLayout />
      },
      {
        path: 'docs',
        children: documentationRoutes,
        element: <DocsLayout />
      },
      {
        path: 'dev',
        children: developmentRoutes,
        element: <ExtendedSidebarLayout />
      }
    ]
  },
  {
    path: '*',
    element: <Status404 />
  }
];

export default router;
