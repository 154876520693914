import { lazy, Suspense } from 'react';
import SuspenseLoader from 'components/core/SuspenseLoader';
import { Navigate } from 'react-router-dom';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Help pages

const Support = Loader(lazy(() => import('pages/supportPage')));

const helpRoutes = [
  {
    path: '',
    element: <Navigate to="support" replace />
  },
  {
    path: 'support',
    element: <Support />
  }
];

export default helpRoutes;
