import { useContext } from 'react';

import {
  Box,
  alpha,
  Stack,
  lighten,
  Divider,
  IconButton,
  Tooltip,
  styled,
  useTheme, BoxProps
} from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from 'contexts/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';

import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';

// I would have wanted to pass in props here to remove duplication, however
// React complained about unknown props on a DOM element:
//   warning: React does not recognize the `$fullSidebar` prop on a DOM element.
//
// I looked into transient props from styled-components, but they wouldn't work
// at all. https://stackoverflow.com/a/61503945
//
// If you come across this and know how to clean it up, I'd be much obliged.
const HeaderWrapperFull = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background, 0.95)};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
`
);

const HeaderWrapperMin = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background, 0.95)};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: 0;
            width: 100%;
        }
`
)

function Header() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const { fullSidebar } = useContext(SidebarContext);
  const theme = useTheme();

  const boxShadow = {
    boxShadow:
      theme.palette.mode === 'dark'
        ? `0 1px 0 ${alpha(
          lighten(theme.colors.primary.main, 0.7),
          0.15
        )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
        : `0px 2px 8px -3px ${alpha(
          theme.colors.alpha.black[100],
          0.2
        )}, 0px 5px 22px -4px ${alpha(
          theme.colors.alpha.black[100],
          0.1
        )}`

  }

  const content = (
    <>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        alignItems="center"
        spacing={2}
      >
      </Stack>
      <Box display="flex" alignItems="center">
        <HeaderButtons />
        <HeaderUserbox />
        <Box
          component="span"
          sx={{
            ml: 2,
            display: { lg: 'none', xs: 'inline-block' }
          }}
        >
          <Tooltip arrow title="Toggle Menu">
            <IconButton color="primary" onClick={toggleSidebar}>
              {!sidebarToggle ? (
                <MenuTwoToneIcon fontSize="small" />
              ) : (
                <CloseTwoToneIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </>
  );

  return (
    <>
      { fullSidebar ? (
        <HeaderWrapperFull
          display="flex"
          alignItems="center"
          sx={boxShadow}
        >
          {content}
        </HeaderWrapperFull>
      ) : (
        <HeaderWrapperMin
          display="flex"
          alignItems="center"
          sx={boxShadow}
        >
          {content}
        </HeaderWrapperMin>
      )}
    </>
  );
}

export default Header;
