import { lazy, Suspense } from 'react';
import SuspenseLoader from 'components/core/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const FormPersonal = Loader(lazy(() => import('pages/apply/Flows/Form/Personal')));
const FormExperience = Loader(lazy(() => import('pages/apply/Flows/Form/Experience')));
const FormEducation = Loader(lazy(() => import('pages/apply/Flows/Form/Education')));
const FormOptional = Loader(lazy(() => import('pages/apply/Flows/Form/Optionals')));

// Optionals
const FormCertifications = Loader(lazy(() => import('pages/apply/Flows/Form/Optionals/Certifications')));
const FormProjects = Loader(lazy(() => import('pages/apply/Flows/Form/Optionals/Projects')));
const FormAwards = Loader(lazy(() => import('pages/apply/Flows/Form/Optionals/Awards')));
const FormLanguages = Loader(lazy(() => import('pages/apply/Flows/Form/Optionals/Languages')));
const FormVolunteering = Loader(lazy(() => import('pages/apply/Flows/Form/Optionals/Volunteering')));
const FormReferences = Loader(lazy(() => import('pages/apply/Flows/Form/Optionals/References')));
const FormInterests = Loader(lazy(() => import('pages/apply/Flows/Form/Optionals/Interests')));

export const formRoutes = [
  {
    path: ':positionId/form/personal-details',
    element: <FormPersonal />
  },
  {
    path: ':positionId/form/education',
    element: <FormEducation />
  },
  {
    path: ':positionId/form/work-experience',
    element: <FormExperience />
  },
  {
    path: ':positionId/form/optional-info',
    element: <FormOptional />
  },
  // Optional info routes
  {
    path: ':positionId/form/optional-info/certifications',
    element: <FormCertifications />
  },
  {
    path: ':positionId/form/optional-info/projects',
    element: <FormProjects />
  },
  {
    path: ':positionId/form/optional-info/awards',
    element: <FormAwards />
  },
  {
    path: ':positionId/form/optional-info/languages',
    element: <FormLanguages />
  },
  {
    path: ':positionId/form/optional-info/volunteering',
    element: <FormVolunteering />
  },
  {
    path: ':positionId/form/optional-info/references',
    element: <FormReferences />
  },
  {
    path: ':positionId/form/optional-info/interests',
    element: <FormInterests />
  }
];
