import { ReactNode, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'hooks/useAuth';
import { Permission } from 'types/shared/permissions';

interface AuthorisedProps {
  requiredPermission: Permission;
  children: ReactNode;
}

const Authenticated = (props: AuthorisedProps) => {
  const { children, requiredPermission } = props;
  const { can } = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  if (!can(requiredPermission)) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }
    return <Navigate to={'/status/404'} />;
  }

  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

Authenticated.propTypes = {
  children: PropTypes.node
};

export default Authenticated;
