import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'components/core/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Docs

const Welcome = Loader(lazy(() => import('pages/docs/Welcome')));
const UserManagement = Loader(lazy(() => import('pages/docs/UserManagement')));
const Auditing = Loader(lazy(() => import('pages/docs/Auditing')));
const Languages = Loader(lazy(() => import('pages/docs/Languages')));
const Customisation = Loader(lazy(() => import('pages/docs/Customisation')));
const Support = Loader(lazy(() => import('pages/docs/Support')));

const documentationRoutes = [
  {
    path: '',
    element: <Navigate to="welcome" replace />
  },
  {
    path: 'welcome',
    element: <Welcome />
  },
  {
    path: 'user-management',
    element: <UserManagement />
  },
  {
    path: 'auditing',
    element: <Auditing />
  },
  {
    path: 'languages',
    element: <Languages />
  },
  {
    path: 'customisation',
    element: <Customisation />
  },
  {
    path: 'support',
    element: <Support />
  }
];

export default documentationRoutes;
