import { BACKEND_API, deleteUrl, getUrl, postUrl } from 'helpers/http';
import { DeleteFileRequest, GetPrivateFileURLRequest } from 'types/shared/api/fileManager';

export const getPublicFileList = async () => {
  return getUrl(BACKEND_API.PRISMA, '/file/public');
};

export const uploadPublicFiles = async (body: any) => {
  return postUrl(BACKEND_API.PRISMA, '/file/public', body, true);
};

export const deletePublicFile = async (request: DeleteFileRequest) => {
  return deleteUrl(BACKEND_API.PRISMA, '/file/public', request);
};

export const getPrivateFileList = async () => {
  return getUrl(BACKEND_API.PRISMA, '/file/private');
};

export const createPrivateFileURL = async (request: GetPrivateFileURLRequest) => {
  return postUrl(BACKEND_API.PRISMA, '/file/private/signedURL', request);
};

export const uploadPrivateFiles = async (body: any) => {
  return postUrl(BACKEND_API.PRISMA, '/file/private', body, true);
};

export const deletePrivateFile = async (request: DeleteFileRequest) => {
  return deleteUrl(BACKEND_API.PRISMA, '/file/private', request);
};
