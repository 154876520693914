import { Box, Button, Card, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import LogoSign from 'components/common/LogoSign';

const HeaderWrapper = styled(Card)(
  ({ theme }) => `
    width: 100%;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    z-index: 6;
    top: 0;
    padding: ${theme.spacing(0, 2)};
    height: ${theme.header.height};
`
);

function Header() {
  const { t }: { t: any } = useTranslation();

  return (
    <HeaderWrapper>
      <Box>
        <LogoSign />
      </Box>
      <Box>
        {/*Will add this back when the guide has been localised*/}
        {/*<LanguageSwitcher />*/}
        <Button
          component={RouterLink}
          to="/app/applications"
          variant="contained"
          sx={{
            mx: 2
          }}
        >
          {t('Return to application')}
        </Button>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
