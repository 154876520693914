import { Box, Link, styled, Typography } from '@mui/material';
import { useContext } from 'react';
import { EnvironmentContext } from 'contexts/EnvironmentContext';

const FooterWrapper = styled(Box)(
  ({ theme }) => `
      border-radius: 0;
      background-color: ${theme.colors.alpha.black[10]};
      padding-bottom: 1rem;
`
);

const FooterInner = styled(Box)`
  margin-left: auto;
  margin-right: auto;
`;

const BottomRow = styled(Box)(
  ({ theme }) => `
    padding-top: 1rem;
  `
);

const Footer = () => {
  const { publicTenantConfig } = useContext(EnvironmentContext);
  const companyDetails = publicTenantConfig.companyDetails;

  return (
    <FooterWrapper className="footer-wrapper">
      <FooterInner>
        <BottomRow
          display={{ xs: 'block', sm: 'flex' }}
          px={2}
          alignItems="center"
          textAlign={{ xs: 'center', sm: 'left' }}
          justifyContent="space-between"
        >
          <Box>
            <Typography variant="subtitle1">
              &copy; {new Date().getFullYear()} - {companyDetails.name}
            </Typography>
          </Box>
          <Typography
            sx={{
              pt: { xs: 2, md: 0 }
            }}
            variant="subtitle1"
          >
            {' '}
            Powered by{' '}
            <Link href="https://openresume.info" target="_blank" rel="noopener noreferrer">
              Prisma
            </Link>
          </Typography>
        </BottomRow>
      </FooterInner>
    </FooterWrapper>
  );
};

export default Footer;
