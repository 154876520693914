/* eslint-disable no-console */

export const logError = (err: Error, message?: string) => {
    if (process.env.REACT_APP_ENV === 'development' || process.env.NODE_ENV === 'development') {
        console.error(err);
        console.log(message);
    }
}

export const logInfo = (message: string) => {
    if (process.env.REACT_APP_ENV === 'development' || process.env.NODE_ENV === 'development') {
        console.log(message);
    }
}
