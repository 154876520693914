import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Header as SiteHeader } from 'layouts/ApplyLayout/Header';
import { Outlet } from 'react-router-dom';
import { Box, styled } from '@mui/material';
import Footer from 'layouts/ApplyLayout/Footer';
import { HeaderProgress } from 'layouts/ApplyLayout/Progress';
import { ApplicationProgressProvider } from 'contexts/ApplicationProgress';

interface JobSiteLayoutProps {
  children?: ReactNode;
}

const Wrapper = styled(Box)(
  ({ theme }) => `
    overflow: auto;
    flex: 1;
    overflow-x: hidden;
`
);

const Header = styled(SiteHeader)`
  z-index: 5;
`;

const MainContent = styled(Box)(
  ({ theme }) => `
    padding-top: ${theme.spacing(8)};
    padding-bottom: ${theme.spacing(8)};
    min-height: calc(100vh - 55px);
  `
);

const ContentContainer = styled(Box)(
  ({ theme }) => `
    margin-left: auto;
    margin-right: auto;
  `
);

const ApplyLayout: FC<JobSiteLayoutProps> = ({ children }) => {
  return (
    <Wrapper>
      <ApplicationProgressProvider>
        <Header />
        <HeaderProgress />
        <MainContent id="main-content" px={{ xs: 2, md: 0 }}>
          <ContentContainer maxWidth="md">{children || <Outlet />}</ContentContainer>
        </MainContent>
        <Footer />
      </ApplicationProgressProvider>
    </Wrapper>
  );
};

ApplyLayout.propTypes = {
  children: PropTypes.node
};

export default ApplyLayout;
