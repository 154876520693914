import { createContext, useCallback, useEffect, useState } from 'react';
import useRefMounted from 'hooks/useRefMounted';
import { checkActive, checkBilling, checkDomain, getPublicTenantConfig } from 'api/public/tenantConfig';
import { AccountBillingStatus, PublicTenantConfig } from 'types/shared/api/tenantConfig';

type EnvironmentContextType = {
  environmentName: string;
  tenantId: string;
  tenantName: string;
  tenantActive: boolean;
  publicTenantConfig: PublicTenantConfig;
  initialised: boolean;
  billingStatus: AccountBillingStatus;
};

export enum EnvironmentName {
  DEMO = 'demo',
  DEVELOP = 'develop',
  PROD = 'prod',
  LOCAL = 'local'
}

export const EnvironmentContext = createContext<EnvironmentContextType>({} as EnvironmentContextType);

export const EnvironmentProvider = ({ children }) => {
  const environmentName = process.env.REACT_APP_ENV;
  const redirectUrl = process.env.REACT_APP_UNKNOWN_TENANT_REDIRECT_URL;

  const [tenantName, setTenantName] = useState<string>();
  const [tenantId, setTenantId] = useState<string>();
  // Default to true since we don't want to show the "tenant is not active" message while the app
  // is loading and we haven't confirmed their billing yet
  const [tenantActive, setTenantActive] = useState<boolean>(true);
  const [initialised, setInitialised] = useState<boolean>(false);
  const [publicTenantConfig, setPublicTenantConfig] = useState<PublicTenantConfig>();
  const [billingStatus, setBillingStatus] = useState<AccountBillingStatus>();

  const isMountedRef = useRefMounted();

  // There are a number of checks we make before the website loads:
  //
  // 1. Use the domain name the user entered to determine if there is a tenant associated with it. If so,
  // then initialise the app with that tenant. Otherwise, redirect to the main sales site.
  //
  // 2. See if the account is active, if it isn't, it means the customer has cancelled their subscription, so we shouldn't
  // render the site.
  //
  // 3. See if the payment is on time. If it's late, then we show a message. If it's very late, then the account will already
  // have been set to inactive.
  const initialiseEnvironment = useCallback(async () => {
    try {
      const currentAddress = window.location.href;
      const checkDomainResponse = await checkDomain(currentAddress);

      // Tenant does not exist
      if (checkDomainResponse.status === 404) {
        window.location.href = redirectUrl;
        return;
      }

      // If no domain, don't render the site.
      if (!checkDomainResponse.data || checkDomainResponse.status !== 200) {
        return;
      }

      const receivedTenantId = checkDomainResponse.data;
      setTenantId(receivedTenantId);

      // Fetch the config associated with the tenant
      const tenantConfigResponse = await getPublicTenantConfig(receivedTenantId);
      if (tenantConfigResponse.data && checkDomainResponse.status === 200) {
        setPublicTenantConfig(tenantConfigResponse.data);
        setTenantName(tenantConfigResponse.data.companyDetails.name);
      }

      // Check to see if the site is active
      const tenantActiveResponse = await checkActive(receivedTenantId);
      if (tenantActiveResponse.data) {
        setTenantActive(true);
        setInitialised(true);
      } else {
        setTenantActive(false);
      }

      // Check the user has paid their subscription
      const billingStatus = await checkBilling(receivedTenantId);
      setBillingStatus(billingStatus.data);

      return;

      // Any error, redirect to the sales site.
    } catch (err) {
      console.error(err);
      window.location.href = redirectUrl;
    }
  }, [isMountedRef]);

  useEffect(() => {
    initialiseEnvironment();
  }, [isMountedRef]);

  return (
    <EnvironmentContext.Provider
      value={{ environmentName, tenantId, tenantName, tenantActive, publicTenantConfig, initialised, billingStatus }}
    >
      {children}
    </EnvironmentContext.Provider>
  );
};
