import { JobPositionListing } from 'types/shared/api/jobPosition';
import { PublicWorkflowSummary } from 'types/shared/api/hiringWorkflow';
import { createContext, useEffect, useState } from 'react';
import { OpenResumeSchema } from 'types/shared/openresume/resume-schema';

const Validator = require('jsonschema').Validator;
const validator = new Validator();

type ApplicationProgressContextType = {
  progressPercent: number; // Progress bar progress
  application: OpenResumeSchema; // Form data for the user's application
  jobListing: JobPositionListing; // The listing the user applied to
  workflow: PublicWorkflowSummary; // The workflow associated with the listing

  // Whether the candidate has reached the summary. If the candidate needs to return to the summary after editing
  // a field, they shouldn't have to go through the entire form again.
  summaryReached: boolean;

  setProgressPercent: (value: number) => void; // Sets the progress bar to a value between 0 and 1.
  setSummaryReached: (value: boolean) => void; // Set to true when the user reaches the summary page.
  setApplicationData: (value: any) => void; // Sets the updated job application and saves it to local storage.
  setJobListingData: (listing: JobPositionListing) => void; // Sets the information about the job listing applied to
  setWorkflowData: (workflow: PublicWorkflowSummary) => void; // Sets the information about the workflow applied to
  isSectionComplete: (sectionName: string, schema: object) => boolean; // Returns true if the section is complete
  getApplicationSection: (sectionName: string) => object; // Retrieves a part of the saved application data
  setApplicationSection: (sectionName: string, data: object) => void; // Sets a part of the saved application data
};

export const ApplicationProgressContext = createContext<ApplicationProgressContextType>(
  {} as ApplicationProgressContextType
);

export const ApplicationProgressProvider = ({ children }) => {
  const [progressPercent, setProgressPercent] = useState<number>(0);
  const [summaryReached, setSummaryReached] = useState<boolean>(false);
  const [application, setApplication] = useState<OpenResumeSchema>({});
  const [jobListing, setJobListing] = useState<JobPositionListing>(undefined);
  const [workflow, setWorkflow] = useState<PublicWorkflowSummary>(undefined);

  useEffect(() => {
    const storedApplication = JSON.parse(localStorage.getItem('currentJobApplication') as string);
    const storedListing = JSON.parse(localStorage.getItem('currentJobListing') as string);
    const storedWorkflow = JSON.parse(localStorage.getItem('currentWorkflow') as string);

    if (storedApplication) setApplication(storedApplication);
    if (storedListing) setJobListing(storedListing);
    if (storedWorkflow) setWorkflow(storedWorkflow);
  }, []);

  const setApplicationData = (newData: OpenResumeSchema) => {
    setApplication(newData);
    localStorage.setItem('currentJobApplication', JSON.stringify(newData));
  };

  const setJobListingData = (newListing: JobPositionListing) => {
    setJobListing(newListing);
    localStorage.setItem('currentJobListing', JSON.stringify(newListing));
  };

  const setWorkflowData = (newWorkflow: PublicWorkflowSummary) => {
    setWorkflow(newWorkflow);
    localStorage.setItem('currentWorkflow', JSON.stringify(newWorkflow));
  };

  const isSectionComplete = (sectionName: string, schema: object): boolean => {
    // Check the application exists. Should never be undefined at this point, but
    // this check makes TypeScript happy
    if (!application) {
      return false;
    }
    // Check the section is defined, if it isn't, then it hasn't been completed
    if (!application[sectionName]) {
      return false;
    }
    // If the section is an array, but has no entries, then it can't be considered
    // completed (this only happens for optional sections)
    if (Array.isArray(application[sectionName])) {
      const optionalArray = application[sectionName] as Array<unknown>;
      if (optionalArray.length === 0) {
        return false;
      }
    }
    // Validate against the JSON schema. In this case, the form needs to be valid
    // to be considered completed
    const response = validator.validate(application[sectionName], schema);
    return response.valid;
  };

  const getApplicationSection = (sectionName: string): object => {
    if (!application) {
      return {};
    }
    return application[sectionName] as object;
  };

  const setApplicationSection = (sectionName: string, data: object | any[]) => {
    let updatedApplication = application;
    updatedApplication[sectionName] = data;
    setApplicationData(updatedApplication);
  };

  return (
    <ApplicationProgressContext.Provider
      value={{
        progressPercent,
        summaryReached,
        application,
        jobListing,
        workflow,
        setProgressPercent,
        setSummaryReached,
        setApplicationData,
        setJobListingData,
        setWorkflowData,
        isSectionComplete,
        getApplicationSection,
        setApplicationSection
      }}
    >
      {children}
    </ApplicationProgressContext.Provider>
  );
};
