import { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import SuspenseLoader from 'components/core/SuspenseLoader';
import Authorised from 'components/core/Routes/Authorised';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Management

const Utils = Loader(lazy(() => import('pages/devUtils')));

const developmentRoutes = [
  {
    path: '',
    element: <Navigate to="utils" replace />
  },
  {
    path: 'utils',
    element: (
      <Authorised requiredPermission={{ feature: 'DEVELOPMENT', action: 'VIEW' }}>
        <Outlet />
      </Authorised>
    ),
    children: [
      {
        path: '',
        element: <Utils />
      }
    ]
  }
];

export default developmentRoutes;
