import { lazy, Suspense } from 'react';

import SuspenseLoader from 'components/core/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const PositionsList = Loader(lazy(() => import('pages/features/positions')));
const PositionViewer = Loader(lazy(() => import('pages/features/positions/single')));
const CreatePosition = Loader(lazy(() => import('pages/features/positions/create')));
const EditPosition = Loader(lazy(() => import('pages/features/positions/edit')));

const positionsRoutes = [
  {
    path: '',
    element: <PositionsList />
  },
  {
    path: 'create',
    element: <CreatePosition />
  },
  {
    path: 'view/:positionId',
    element: <PositionViewer />
  },
  {
    path: 'edit/:positionId',
    element: <EditPosition />
  }
];

export default positionsRoutes;
