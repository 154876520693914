import { FC, ReactNode, useContext } from 'react';
import { alpha, Box, lighten, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';

import SidebarExpanded from './Expanded';
import SidebarCollapsed from './Collapsed';
import Header from './Header';
import { SidebarContext } from 'contexts/SidebarContext';

interface ExtendedSidebarLayoutProps {
  children?: ReactNode;
}

const ExtendedSidebarLayout: FC<ExtendedSidebarLayoutProps> = () => {
  const theme = useTheme();
  const { fullSidebar } = useContext(SidebarContext);

  const expandedBoxStyle = {
    position: 'relative',
    zIndex: 5,
    display: 'block',
    flex: 1,
    pt: `${theme.header.height}`,
    [theme.breakpoints.up('lg')]: {
      ml: `${theme.sidebar.width}`
    }
  };

  const minimisedBoxStyle = {
    position: 'relative',
    zIndex: 5,
    display: 'block',
    flex: 1,
    pt: `${theme.header.height}`,
    [theme.breakpoints.up('md')]: {
      ml: theme.spacing(12)
    }
  };

  return (
    <>
      <Box
        sx={{
          flex: 1,
          height: '100%',

          '.MuiPageTitle-wrapper': {
            background: theme.palette.mode === 'dark' ? theme.colors.alpha.trueWhite[5] : theme.colors.alpha.white[50],
            marginBottom: `${theme.spacing(4)}`,
            boxShadow:
              theme.palette.mode === 'dark'
                ? `0 1px 0 ${alpha(
                    lighten(theme.colors.primary.main, 0.7),
                    0.15
                  )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
                : `0px 2px 4px -3px ${alpha(theme.colors.alpha.black[100], 0.1)}, 0px 5px 12px -4px ${alpha(
                    theme.colors.alpha.black[100],
                    0.05
                  )}`
          }
        }}
      >
        <Header />
        {fullSidebar && <SidebarExpanded />}
        {!fullSidebar && <SidebarCollapsed />}
        <Box sx={fullSidebar ? expandedBoxStyle : minimisedBoxStyle}>
          <Box display="block">
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ExtendedSidebarLayout;
