import { List, ListSubheader } from '@mui/material';
import { matchPath, useLocation } from 'react-router-dom';
import SidebarMenuItem from './item';
import menuItems, { MenuItem } from 'layouts/SidebarLayout/items';
import { useTranslation } from 'react-i18next';
import { MenuWrapper, SubMenuWrapper } from './index.styles';
import useAuth from 'hooks/useAuth';
import { Permission } from 'types/shared/permissions';

const renderSidebarMenuItems = ({ items, path }: { items: MenuItem[]; path: string }): JSX.Element => (
  <SubMenuWrapper>
    <List component="div">{items.reduce((ev, item) => reduceChildRoutes({ ev, item, path }), [])}</List>
  </SubMenuWrapper>
);

const reduceChildRoutes = ({
  ev,
  path,
  item
}: {
  ev: JSX.Element[];
  path: string;
  item: MenuItem;
}): Array<JSX.Element> => {
  const key = item.name;
  const exactMatch = item.link
    ? !!matchPath(
        {
          path: item.link,
          end: true
        },
        path
      )
    : false;

  if (item.items) {
    const partialMatch = item.link
      ? !!matchPath(
          {
            path: item.link,
            end: false
          },
          path
        )
      : false;

    ev.push(
      <SidebarMenuItem
        key={key}
        active={partialMatch}
        open={partialMatch}
        name={item.name}
        icon={item.icon}
        link={item.link}
        badge={item.badge}
        badgeTooltip={item.badgeTooltip}
      >
        {renderSidebarMenuItems({
          path,
          items: item.items
        })}
      </SidebarMenuItem>
    );
  } else {
    ev.push(
      <SidebarMenuItem
        key={key}
        active={exactMatch}
        name={item.name}
        link={item.link}
        badge={item.badge}
        badgeTooltip={item.badgeTooltip}
        icon={item.icon}
      />
    );
  }

  return ev;
};

const userCanViewSection = (can: (Permission) => boolean, permission: Permission): boolean => {
  if (!permission) {
    return true;
  }
  return can(permission);
};

function SidebarMenu() {
  const location = useLocation();
  const { t }: { t: any } = useTranslation();
  const { can } = useAuth();

  return (
    <>
      {menuItems.map((section) => {
        return (
          userCanViewSection(can, section.permission) && (
            <MenuWrapper key={section.heading}>
              <List
                component="div"
                subheader={
                  <ListSubheader component="div" disableSticky>
                    {t(section.heading)}
                  </ListSubheader>
                }
              >
                {renderSidebarMenuItems({
                  items: section.items,
                  path: location.pathname
                })}
              </List>
            </MenuWrapper>
          )
        );
      })}
    </>
  );
}

export default SidebarMenu;
