import { styled, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TypographyH1 } from 'pages/util/Auth/Login/Login.styles';

const LoginDetailsTable = styled(Table)(
  ({ theme }) => `
    border: 1px solid ${theme.colors.alpha.black[10]};
    overflow-x: auto;
    width: 100%;
    border-collapse: collapse;
`
);

const TableCellSmall = styled(TableCell)(
  ({ theme }) => `
    font-size: 0.7rem;
  `
);

const DemoText = () => {
  const { t }: { t: any } = useTranslation();
  return (
    <>
      <TypographyH1 variant="h1" sx={{ mb: 5, textAlign: 'center' }}>
        {t('Welcome to the Prisma Demo')}
      </TypographyH1>
      <Typography variant="subtitle1" sx={{ mb: 3 }}>
        {t(
          'This demo allows you to explore the Prisma platform without having to schedule a demo with us. ' +
            ' If you would rather have a guided tour, reach out to us at: '
        )}
        <strong>sales@openresume.info</strong>
      </Typography>
      <Typography variant="subtitle1" color="text.primary" fontWeight="bold">
        {t('Important details')}
      </Typography>
      <Typography variant="subtitle1">
        {t('Any changes made to this environment are reset at the end of each day, at midnight UTC.')}{' '}
      </Typography>
      <Typography variant="subtitle1" color="text.primary" fontWeight="bold" sx={{ pt: 3 }}>
        {t('Login Details')}
      </Typography>
      <Typography variant="subtitle1" sx={{ pb: 3 }}>
        {t('All of the following accounts have the password: Prisma123!')}{' '}
      </Typography>
      <LoginDetailsTable>
        <TableHead>
          <TableRow>
            <TableCell>Role</TableCell>
            <TableCell>Email</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCellSmall>Admin</TableCellSmall>
            <TableCellSmall>demo+admin@openresume.info</TableCellSmall>
          </TableRow>
          <TableRow>
            <TableCellSmall>Standard</TableCellSmall>
            <TableCellSmall>demo+standard@openresume.info</TableCellSmall>
          </TableRow>
          <TableRow>
            <TableCellSmall>Read only</TableCellSmall>
            <TableCellSmall>demo+readonly@openresume.info</TableCellSmall>
          </TableRow>
        </TableBody>
      </LoginDetailsTable>
    </>
  );
};

export default DemoText;
