// Mapping job position enums to readable text
const positionTranslations = {
  PERMANENT: 'Full time (Permanent)',
  CONTRACT: 'Contract',
  PART_TIME: 'Part time',
  TEMPORARY: 'Temporary',
  // Working pattern
  ON_SITE: 'Office',
  HYBRID: 'Hybrid',
  REMOTE: 'Remote',
  FLEXIBLE: 'Flexible',
  // Job position status
  DRAFT: 'Draft',
  LIVE: 'Live',
  ARCHIVED: 'Archived',
  // Job position level TODO: Make these configurable
  ENTRY_LEVEL: 'Entry level',
  EARLY_CAREER: 'Early career',
  EXPERIENCED: 'Mid-weight',
  SENIOR: 'Senior',
  DIRECTOR: 'Director',
  OTHER: 'Other',
  // Department
  sales: 'Sales',
  'product-management': 'Product management',
  marketing: 'Marketing',
  operations: 'Operations',
  development: 'Development',
  'customer-support': 'Customer support'
};

// Mapping workflow constants to readable text
const workflowTranslations = {
  APPLICATION: 'Application form',
  APPLICATION_RECEIVED: 'Application received',
  SCREENING: 'Screening',
  EVALUATION: 'Applicant evaluation',
  INTERVIEW: 'Interview',
  OFFER: 'Offer',
  CUSTOM: 'Custom stage',
  INTEGRATION: 'Third party integration',
  PRISMA: 'Prisma',
  CUSTOMER: 'Customer',
  PARTNER: 'Partner'
};

const applicationTranslations = {
  'application.rejectionReason.NOT_ENOUGH_INFO.title': 'Not enough information',
  'application.rejectionReason.NOT_ENOUGH_INFO.description':
    "The Application didn't have enough information to make a decision.",
  'application.rejectionReason.POSITION_FILLED.title': 'Position already filled',
  'application.rejectionReason.POSITION_FILLED.description':
    'This position has already been filled by another candidate',
  'application.rejectionReason.NOT_ENOUGH_EXPERIENCE.title': 'Not enough experience',
  'application.rejectionReason.NOT_ENOUGH_EXPERIENCE.description':
    'This position requires more experience than the candidate can offer.',
  'application.rejectionReason.NO_CULTURE_FIT.title': 'No culture fit',
  'application.rejectionReason.NO_CULTURE_FIT.description':
    "The candidate will not add to the culture we're trying to build.",
  'application.rejectionReason.NO_COMMUNICATION.title': 'No communication',
  'application.rejectionReason.NO_COMMUNICATION.description':
    'There has been no communication from the candidate for a while, we assume they are no longer interested.',
  'application.rejectionReason.MISSED_APPOINTMENT.title': 'Missed appointment',
  'application.rejectionReason.MISSED_APPOINTMENT.description':
    'The candidate failed to appear for a call or an interview.',
  'application.futureAction.APPLY_AGAIN_LATER.title': 'Invite to apply again later',
  'application.futureAction.APPLY_AGAIN_LATER.description':
    'Invite the candidate to apply again for this role in the future.',
  'application.futureAction.APPLY_FOR_DIFFERENT_ROLE.title': 'Invite to apply again for a different role',
  'application.futureAction.APPLY_FOR_DIFFERENT_ROLE.description':
    'Send a different role to the candidate and invite them to apply again.',
  'application.futureAction.NO_ACTION.title': 'No action',
  'application.futureAction.NO_ACTION.description': 'Do not send any further communications to the candidate.'
};

// Mapping data types to readable text
const dataTranslations = {
  true: 'Yes',
  false: 'No'
};

const enJSON = {
  ...positionTranslations,
  ...dataTranslations,
  ...workflowTranslations,
  ...applicationTranslations
};

export default enJSON;
