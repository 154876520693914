import { Auth } from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { RoleType } from 'types/shared/permissions';

const ROLE_ATTRIBUTE = "custom:user-role";

export const getAuthToken = async () => {
    try {
        const session = await Auth.currentSession();
        if (!isAuthorised(session)) {
            await Auth.signOut();
        }
        const token = session.getIdToken();
        return token.getJwtToken();
    } catch (err) {
        // No user, hence no token, however the user can still use public endpoints.
       return Promise<undefined>;
    }
}

export const isAuthorised = (session: CognitoUserSession | null): boolean => {
    const token = session && session.getIdToken();
    if (!token) {
        return false;
    }
    return token.payload[ROLE_ATTRIBUTE] !== RoleType.DEACTIVATED;
}