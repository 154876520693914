import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import Footer from 'components/layout/Footer';
import Sidebar from './Sidebar';
import { Box, Card, Container, Grid, styled } from '@mui/material';
import { Outlet } from 'react-router-dom';

interface DocsLayoutProps {
  children?: ReactNode;
}

const MainWrapper = styled(Box)(
  () => `
    flex: 1;
    display: flex;
    height: 100%;
`
);

const MainContent = styled(Box)(
  ({ theme }) => `
    flex: 1;
    margin-top: ${theme.spacing(10)};
    overflow: auto;
`
);

const ResponsiveHeightGrid = styled(Grid)`
  min-height: calc(100vh - 206px);
`;

const DocsLayout: FC<DocsLayoutProps> = ({ children }) => {
  return (
    <>
      <Header />
      <MainWrapper>
        <Sidebar />
        <MainContent>
          <ResponsiveHeightGrid>
            <Container maxWidth="lg">
              <Card
                sx={{
                  minHeight: 650,
                  mt: 2,
                  pb: 3,
                  mb: 6
                }}
              >
                {children || <Outlet />}
              </Card>
            </Container>
          </ResponsiveHeightGrid>
          <Footer />
        </MainContent>
      </MainWrapper>
    </>
  );
};

DocsLayout.propTypes = {
  children: PropTypes.node
};

export default DocsLayout;
