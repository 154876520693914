import { Box, IconButton, Link, styled, Typography } from '@mui/material';

import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import useRefMounted from 'hooks/useRefMounted';
import { useCallback, useContext, useEffect, useState } from 'react';
import { getPublicSiteConfig } from 'api/public/jobSiteContent';
import { JobSiteConfig } from 'types/shared/api/jobSiteContent';
import { EnvironmentContext } from 'contexts/EnvironmentContext';

const Icons = {
  TWITTER: <TwitterIcon />,
  FACEBOOK: <FacebookIcon />,
  LINKEDIN: <LinkedInIcon />,
  YOUTUBE: <YouTubeIcon />,
  INSTAGRAM: <InstagramIcon />
};

const FooterWrapper = styled(Box)(
  ({ theme }) => `
      border-radius: 0;
      background-color: ${theme.colors.alpha.black[10]};
      padding-bottom: 1rem;
`
);

const FooterInner = styled(Box)`
  margin-left: auto;
  margin-right: auto;
`;

const SocialRow = styled(Box)(
  ({ theme }) => `
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${theme.colors.alpha.black[10]};
    padding-top: 1rem;
    padding-bottom: 1rem;
    justify-content: space-between;
  `
);

const SocialIcons = styled(Box)`
  display: flex;
  align-items: center;
`;

const SocialText = styled(Typography)(
  ({ theme }) => `
    font-weight: bold;
  `
);

const BottomRow = styled(Box)(
  ({ theme }) => `
    padding-top: 1rem;
  `
);

function Footer() {
  const isMountedRef = useRefMounted();
  const [config, setConfig] = useState<JobSiteConfig>(undefined);
  const { tenantId, publicTenantConfig } = useContext(EnvironmentContext);

  const fetchPage = useCallback(async () => {
    try {
      const { data: config } = await getPublicSiteConfig(tenantId);
      if (isMountedRef.current) {
        setConfig(config);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    fetchPage();
  }, [fetchPage]);

  return (
    <FooterWrapper className="footer-wrapper">
      <FooterInner maxWidth="lg">
        <SocialRow display={{ xs: 'block', sm: 'flex' }} px={{ xs: 2, lg: 0 }} textAlign={{ xs: 'center', sm: 'left' }}>
          {config && config.socialMedia && config.socialMedia.length > 0 && (
            <SocialIcons display={{ xs: 'block', sm: 'flex' }}>
              <SocialText mr={{ sm: 2, xs: 0 }} pt={{ xs: 1, sm: 0 }}>
                {config && config.pages.layout.footerSocial}
              </SocialText>
              <Box
                sx={{
                  pt: { xs: 1, sm: 0 }
                }}
              >
                {config &&
                  config.socialMedia.map((social, index) => {
                    if (social.link)
                      return (
                        <Link href={social.link} key={index}>
                          <IconButton aria-label={social.site}>{Icons[social.site]}</IconButton>
                        </Link>
                      );
                  })}
              </Box>
            </SocialIcons>
          )}
          <Box display={{ xs: 'none', sm: 'block' }}>
            <Link href={'/app/applications'}>Admin login</Link>
          </Box>
        </SocialRow>
        <Box></Box>
        <BottomRow
          display={{ xs: 'block', sm: 'flex' }}
          px={{ xs: 2, lg: 0 }}
          alignItems="center"
          textAlign={{ xs: 'center', sm: 'left' }}
          justifyContent="space-between"
        >
          <Box>
            <Typography variant="subtitle1">
              &copy; {new Date().getFullYear()} - {publicTenantConfig.companyDetails.name}
            </Typography>
          </Box>
          <Typography
            sx={{
              pt: { xs: 2, md: 0 }
            }}
            variant="subtitle1"
          >
            {' '}
            Powered by{' '}
            <Link href="https://openresume.info" target="_blank" rel="noopener noreferrer">
              Prisma
            </Link>
          </Typography>
        </BottomRow>
      </FooterInner>
    </FooterWrapper>
  );
}

export default Footer;
