import { Suspense, lazy } from 'react';

import SuspenseLoader from 'components/core/SuspenseLoader';
import Guest from 'components/core/Guest';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Account

const Login = Loader(lazy(() => import('pages/util/Auth/Login/Login')));

const Register = Loader(lazy(() => import('pages/util/Auth/Register')));

const RecoverPassword = Loader(lazy(() => import('pages/util/Auth/RecoverPassword')));

const accountRoutes = [
  {
    path: 'login',
    element: (
      <Guest>
        <Login />
      </Guest>
    )
  },
  {
    path: 'register',
    element: (
      <Guest>
        <Register />
      </Guest>
    )
  },
  {
    path: 'login',
    element: <Login />
  },
  {
    path: 'recover-password',
    element: <RecoverPassword />
  },
  {
    path: 'register',
    element: (
      <Guest>
        <Register />
      </Guest>
    )
  },
  {
    path: 'register-cover',
    element: <Register />
  }
];

export default accountRoutes;
