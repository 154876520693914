import { BACKEND_API, deleteUrl, getUrl, patchUrl, postUrl } from 'helpers/http';
import {
  UserCreateRequest, UserDeleteRequest,
  UserEditRequest,
  UserResendInviteRequest,
  UserUpdatePasswordRequest
} from 'types/shared/api/userManagement';
import { RoleType } from 'types/shared/permissions';

export const getUser = async (): Promise<any> => {
  return getUrl(BACKEND_API.PRISMA, `/user`);
}

export const getUsersVisibleToRole = async (role: RoleType): Promise<any> => {
  return getUrl(BACKEND_API.PRISMA, `/visibleUsers/:roleId`);
}

export const createUser = async (user: UserCreateRequest): Promise<any> => {
  return postUrl(BACKEND_API.PRISMA, `/users`, user);
}

export const deleteUser = async (request: UserDeleteRequest): Promise<any> => {
  return deleteUrl(BACKEND_API.PRISMA, `/user`, request);
}

export const resendInvite = async (request: UserResendInviteRequest): Promise<any> => {
  return postUrl(BACKEND_API.PRISMA, `/user/resendInvite`, request);
}

export const updatePassword = async (request: UserUpdatePasswordRequest): Promise<any> => {
  return postUrl(BACKEND_API.PRISMA, `/user/updatePassword`, request);
}

export const editUserAsSelf = async (request: UserEditRequest): Promise<any> => {
  return patchUrl(BACKEND_API.PRISMA, '/user/edit', request);
}

export const editUserAsAdmin = async (request: UserEditRequest): Promise<any> => {
  return patchUrl(BACKEND_API.PRISMA, '/user/adminEdit', request);
}