import { Alert, styled } from '@mui/material';

const BannerWrapper = styled('div')(
  ({ theme }) => `
  margin-top:  ${theme.spacing(6)};
  padding: 0 ${theme.spacing(2)};
  margin-bottom: -84px;
  position: relative;
`
);

const WarningAlert = styled(Alert)(
  ({ theme }) => `
  max-width: 800px;
  margin: auto;
`
);

const DemoBanner = () => {
  return (
    <BannerWrapper>
      <WarningAlert severity="warning" variant="filled" style={{ zIndex: 2 }}>
        This is a demo for <a href="https://tryprisma.com">Prisma</a>, and is not a real hiring site. Job applications
        will not be received on this site.
      </WarningAlert>
    </BannerWrapper>
  );
};

export default DemoBanner;
