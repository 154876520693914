import { lazy, Suspense } from 'react';

import SuspenseLoader from 'components/core/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const HiringWorkflows = Loader(lazy(() => import('pages/features/hiringWorkflows')));
const WorkflowViewer = Loader(lazy(() => import('pages/features/hiringWorkflows/single')));
const CreateWorkflow = Loader(lazy(() => import('pages/features/hiringWorkflows/create')));
const EditWorkflow = Loader(lazy(() => import('pages/features/hiringWorkflows/edit')));

const hiringWorkflowsRoutes = [
  {
    path: '',
    element: <HiringWorkflows />
  },
  {
    path: 'create',
    element: <CreateWorkflow />
  },
  {
    path: 'view/:workflowId',
    element: <WorkflowViewer />
  },
  {
    path: 'edit/:workflowId',
    element: <EditWorkflow />
  }
];

export default hiringWorkflowsRoutes;
