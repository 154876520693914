import { createContext, ReactNode, useContext, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { amplifyConfig } from 'config';
import { getPermissions } from 'api/internal/auth';
import { Permission } from 'types/shared/permissions';
import { getFeatureFlags } from 'api/public/tenantConfig';
import { DashboardUser } from 'types/shared/users';
import { Auth } from 'aws-amplify';
import { EnvironmentContext } from 'contexts/EnvironmentContext';

Auth.configure(amplifyConfig);

interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: DashboardUser | null;
}

interface AuthContextValue extends AuthState {
  method: 'Amplify';
  login: (email: string, password: string) => Promise<LoginAttempt>;
  can: (permission: Permission) => boolean;
  setUserPasswordAfterTempLogin: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  register: (email: string, password: string) => Promise<void>;
  verifyCode: (username: string, code: string) => Promise<void>;
  resendCode: (username: string) => Promise<void>;
  passwordRecovery: (username: string) => Promise<void>;
  passwordReset: (username: string, code: string, newPassword: string) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: DashboardUser | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: DashboardUser;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
};

type VerifyCodeAction = {
  type: 'VERIFY_CODE';
};

type ResendCodeAction = {
  type: 'RESEND_CODE';
};
type PasswordRecoveryAction = {
  type: 'PASSWORD_RECOVERY';
};

type PasswordResetAction = {
  type: 'PASSWORD_RESET';
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | VerifyCodeAction
  | ResendCodeAction
  | PasswordRecoveryAction
  | PasswordResetAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

export interface LoginAttempt {
  ok: boolean;
  errorReason?: string;
}

const handlers: Record<string, (state: AuthState, action: Action) => AuthState> = {
  INITIALIZE: (state: AuthState, action: InitializeAction): AuthState => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state: AuthState, action: LoginAction): AuthState => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state: AuthState): AuthState => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state: AuthState): AuthState => ({ ...state }),
  VERIFY_CODE: (state: AuthState): AuthState => ({ ...state }),
  RESEND_CODE: (state: AuthState): AuthState => ({ ...state }),
  PASSWORD_RECOVERY: (state: AuthState): AuthState => ({ ...state }),
  PASSWORD_RESET: (state: AuthState): AuthState => ({ ...state })
};

const reducer = (state: AuthState, action: Action): AuthState =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'Amplify',
  login: () => Promise.resolve({ ok: true }),
  can: () => false,
  setUserPasswordAfterTempLogin: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  verifyCode: () => Promise.resolve(),
  resendCode: () => Promise.resolve(),
  passwordRecovery: () => Promise.resolve(),
  passwordReset: () => Promise.resolve()
});

const defaultPicture =
  'https://st3.depositphotos.com/13159112/17145/v/450/depositphotos_171453724-stock-illustration-default-avatar-profile-icon-grey.jpg';

export const AuthProvider = (props: AuthProviderProps) => {
  const { children } = props;
  const { tenantId } = useContext(EnvironmentContext);
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const [usedTempPassword, setUsedTempPassword] = useState<string>('');

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const { data } = await getPermissions(user.attributes['custom:user-role']);

        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user: {
              id: user.sub,
              picture: user.attributes.picture ?? defaultPicture,
              email: user.attributes.email,
              name: user.attributes.name,
              role: user.attributes['custom:user-role'],
              account: user.attributes['custom:tenant-account'],
              status: '',
              lastLogin: user.attributes['custom:last-login'],
              created: '',
              permissions: data
            }
          }
        });
      } catch (error) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string): Promise<LoginAttempt> => {
    const user = await Auth.signIn(email, password);

    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      setUsedTempPassword(password);
      return {
        ok: false,
        errorReason: 'NEW_PASSWORD_REQUIRED'
      };
    }

    const { data: userPermissions } = await getPermissions(user.attributes['custom:user-role']);
    const { data: featureFlags } = await getFeatureFlags(tenantId);

    dispatch({
      type: 'LOGIN',
      payload: {
        user: {
          id: user.sub,
          picture: user.attributes.picture,
          email: user.attributes.email,
          name: user.attributes.name,
          account: user.attributes['custom:tenant-account'],
          role: user.attributes['custom:user-role'],
          status: user.status,
          lastLogin: user.attributes['custom:last-login'],
          created: '',
          permissions: userPermissions,
          featureFlags: featureFlags
        }
      }
    });

    return {
      ok: true
    };
  };

  const setUserPasswordAfterTempLogin = async (email: string, newPassword: string): Promise<void> => {
    const userBeforeReset = await Auth.signIn(email, usedTempPassword);
    const userAfterReset = await Auth.completeNewPassword(userBeforeReset, newPassword);

    const { data: userPermissions } = await getPermissions(
      userAfterReset.challengeParam.attributes['custom:user-role']
    );
    const { data: featureFlags } = await getFeatureFlags(tenantId);

    dispatch({
      type: 'LOGIN',
      payload: {
        user: {
          id: userAfterReset.sub,
          picture: userAfterReset.challengeParam.userAttributes.picture,
          email: userAfterReset.challengeParam.userAttributes.email,
          name: userAfterReset.challengeParam.userAttributes.name,
          account: userAfterReset.challengeParam.userAttributes['custom:tenant-account'],
          role: userAfterReset.challengeParam.userAttributes['custom:user-role'],
          status: userAfterReset.challengeParam.userAttributes.status,
          lastLogin: userAfterReset.challengeParam.userAttributes['custom:last-login'],
          created: '',
          permissions: userPermissions,
          featureFlags: featureFlags
        }
      }
    });
  };

  const can = (permission: Permission): boolean => {
    const { feature, action } = permission;
    const permissions = state.user.permissions;
    return (permissions[feature] || []).includes(action);
  };

  const logout = async (): Promise<void> => {
    await Auth.signOut();
    dispatch({
      type: 'LOGOUT'
    });
  };

  const register = async (email: string, password: string): Promise<void> => {
    await Auth.signUp({
      username: email,
      password,
      attributes: { email }
    });
    dispatch({
      type: 'REGISTER'
    });
  };

  const verifyCode = async (username: string, code: string): Promise<void> => {
    await Auth.confirmSignUp(username, code);
    dispatch({
      type: 'VERIFY_CODE'
    });
  };

  const resendCode = async (username: string): Promise<void> => {
    await Auth.resendSignUp(username);
    dispatch({
      type: 'RESEND_CODE'
    });
  };

  const passwordRecovery = async (username: string): Promise<void> => {
    await Auth.forgotPassword(username);
    dispatch({
      type: 'PASSWORD_RECOVERY'
    });
  };

  const passwordReset = async (username: string, code: string, newPassword: string): Promise<void> => {
    await Auth.forgotPasswordSubmit(username, code, newPassword);
    dispatch({
      type: 'PASSWORD_RESET'
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'Amplify',
        login,
        can,
        setUserPasswordAfterTempLogin,
        logout,
        register,
        verifyCode,
        resendCode,
        passwordRecovery,
        passwordReset
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
