import { Box, Card, Container, Link, styled } from '@mui/material';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import DarkModeSwitch from 'components/widgets/DarkModeSwitch';
import { EnvironmentContext } from 'contexts/EnvironmentContext';

const HeaderWrapper = styled(Card)(
  ({ theme }) => `
    width: 100%;
    display: flex;
    align-items: center;
    height: ${theme.spacing(10)};
    border-top-left-radius: 0;
    border-top-right-radius: 0;
`
);

const HeaderLogo = styled('img')(
  ({ theme }) => `
    max-width: 140px;
    
    &:hover {
      cursor: pointer;
    }
  `
);

const LogoContainer = styled(Link)(
  ({ theme }) => `
    border-radius: ${theme.general.borderRadius};
    padding: ${theme.spacing(1)} ${theme.spacing(2)};
    background-color: ${
      theme.palette.mode === 'dark' ? theme.colors.alpha.trueWhite[30] : theme.colors.alpha.white[100]
    }
  `
);

export const Header = () => {
  const navigate = useNavigate();
  const { publicTenantConfig, tenantName } = useContext(EnvironmentContext);
  const companyDetails = publicTenantConfig.companyDetails;

  return (
    <>
      <HeaderWrapper id="header-wrapper">
        <Container maxWidth={'xl'}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <LogoContainer
              onClick={() => navigate('/')}
              role="link"
              style={{ textDecoration: 'none' }}
              ml={{ xs: '-5px', sm: '-15px' }}
            >
              {publicTenantConfig && (
                <HeaderLogo
                  // Prefer the use of SVG, but fall back to PNG if needed
                  src={companyDetails.logos.svg ? companyDetails.logos.svg : companyDetails.logos.png}
                  alt={`${tenantName} company logo`}
                />
              )}
            </LogoContainer>
            <Box sx={{ display: { xs: 'none', md: 'inline-block' } }}>
              <DarkModeSwitch />
            </Box>
          </Box>
        </Container>
      </HeaderWrapper>
    </>
  );
};
