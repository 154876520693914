import { lazy, Suspense } from 'react';

import SuspenseLoader from 'components/core/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

// Status

const Status500 = Loader(lazy(() => import('pages/util/Status/Status500')));
const StatusComingSoon = Loader(lazy(() => import('pages/util/Status/ComingSoon')));
const StatusMaintenance = Loader(lazy(() => import('pages/util/Status/Maintenance')));

const statusRoutes = [
  {
    path: '500',
    element: <Status500 />
  },
  {
    path: 'maintenance',
    element: <StatusMaintenance />
  },
  {
    path: 'coming-soon',
    element: <StatusComingSoon />
  }
];

export default statusRoutes;
