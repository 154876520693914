import SuspenseLoader from 'components/core/SuspenseLoader';
import { lazy, Suspense } from 'react';
import { formRoutes } from 'router/apply/form';
import { Navigate } from 'react-router-dom';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const ApplyOverview = Loader(lazy(() => import('pages/apply/Start/HiringProcess')));
const ApplyMethod = Loader(lazy(() => import('pages/apply/Start/ApplicationType')));
const OpenResumeApply = Loader(lazy(() => import('pages/apply/Flows/OpenResume')));
const LinkedInApply = Loader(lazy(() => import('pages/apply/Flows/LinkedIn')));
const ApplySummary = Loader(lazy(() => import('pages/apply/Finish/Summary')));
const ApplyNextSteps = Loader(lazy(() => import('pages/apply/Finish/NextSteps')));

const applyRoutes = [
  {
    path: '',
    element: <Navigate to="/positions" replace />
  },
  {
    path: ':positionId',
    element: <Navigate to="overview" replace />
  },
  {
    path: ':positionId/overview',
    element: <ApplyOverview />
  },
  {
    path: ':positionId/application-type',
    element: <ApplyMethod />
  },
  {
    path: ':positionId/open-resume',
    element: <OpenResumeApply />
  },
  {
    path: ':positionId/linked-in',
    element: <LinkedInApply />
  },
  {
    path: ':positionId/summary',
    element: <ApplySummary />
  },
  {
    path: ':positionId/next-steps',
    element: <ApplyNextSteps />
  },
  ...formRoutes
];

export default applyRoutes;
