import { useContext, useRef, useState } from 'react';
import {
  Popover,
  Typography,
  Stack,
  Divider,
  Box,
  Tooltip,
  IconButton,
  alpha, useTheme
} from '@mui/material';
import { ThemeContext } from 'theme/ThemeProvider';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import PaletteTwoToneIcon from '@mui/icons-material/PaletteTwoTone';
import { useTranslation } from 'react-i18next';
import { ButtonWrapper, CheckSelected, ColorSchemeWrapper, LightTooltip, ThemeToggleWrapper } from './index.styles';

const ThemeSettings = () => {
  const { t }: { t: any } = useTranslation();
  const themeContext = useTheme();

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const setThemeName = useContext(ThemeContext);
  const curThemeName = localStorage.getItem('appTheme') || 'PureLightTheme';

  const [theme, setTheme] = useState(curThemeName);

  const changeTheme = (theme): void => {
    setTheme(theme);
    setThemeName(theme);
  };

  return (
    <>
      <Box>
        <LightTooltip placement="top" arrow title={t('Theme Settings')}>
          <IconButton
            sx={{
              background: `${themeContext.colors.alpha.trueWhite[10]}`,
              color: `${themeContext.colors.alpha.trueWhite[70]}`,
              transition: `${themeContext.transitions.create(['all'])}`,

              '&:hover': {
                background: `${alpha(themeContext.colors.alpha.trueWhite[100], 0.2)}`,
                color: `${themeContext.colors.alpha.trueWhite[100]}`
              },
              mx: 1
            }}
            ref={ref}
            onClick={handleOpen}
          >
            <PaletteTwoToneIcon fontSize="small" />
          </IconButton>
        </LightTooltip>
        <Popover
          disableScrollLock
          anchorEl={ref.current}
          onClose={handleClose}
          open={isOpen}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
          >
            <ThemeToggleWrapper>
              <Typography
                sx={{
                  mt: 1,
                  mb: 3,
                  textAlign: 'center',
                  fontWeight: 'bold',
                  textTransform: 'uppercase'
                }}
                variant="body1"
              >
                Light colour schemes
              </Typography>
              <Stack alignItems="center" spacing={2}>
                <Tooltip placement="left" title="Pure Light" arrow>
                  <ButtonWrapper
                    className={theme === 'PureLightTheme' ? 'active' : ''}
                    onClick={() => {
                      changeTheme('PureLightTheme');
                    }}
                  >
                    {theme === 'PureLightTheme' && (
                      <CheckSelected>
                        <CheckTwoToneIcon />
                      </CheckSelected>
                    )}
                    <ColorSchemeWrapper className="colorSchemeWrapper pureLight">
                      <Box className="primary" />
                      <Box className="secondary" />
                    </ColorSchemeWrapper>
                  </ButtonWrapper>
                </Tooltip>
                <Tooltip placement="left" title="Grey Goose" arrow>
                  <ButtonWrapper
                    className={theme === 'GreyGooseTheme' ? 'active' : ''}
                    onClick={() => {
                      changeTheme('GreyGooseTheme');
                    }}
                  >
                    {theme === 'GreyGooseTheme' && (
                      <CheckSelected>
                        <CheckTwoToneIcon />
                      </CheckSelected>
                    )}
                    <ColorSchemeWrapper className="colorSchemeWrapper greyGoose">
                      <Box className="primary" />
                      <Box className="secondary" />
                    </ColorSchemeWrapper>
                  </ButtonWrapper>
                </Tooltip>
                <Tooltip placement="left" title="Purple Flow" arrow>
                  <ButtonWrapper
                    className={theme === 'PurpleFlowTheme' ? 'active' : ''}
                    onClick={() => {
                      changeTheme('PurpleFlowTheme');
                    }}
                  >
                    {theme === 'PurpleFlowTheme' && (
                      <CheckSelected>
                        <CheckTwoToneIcon />
                      </CheckSelected>
                    )}
                    <ColorSchemeWrapper className="colorSchemeWrapper purpleFlow">
                      <Box className="primary" />
                      <Box className="secondary" />
                    </ColorSchemeWrapper>
                  </ButtonWrapper>
                </Tooltip>
              </Stack>
            </ThemeToggleWrapper>
            <ThemeToggleWrapper>
              <Typography
                sx={{
                  mt: 1,
                  mb: 3,
                  textAlign: 'center',
                  fontWeight: 'bold',
                  textTransform: 'uppercase'
                }}
                variant="body1"
              >
                Dark colour schemes
              </Typography>
              <Stack alignItems="center" spacing={2}>
                <Tooltip placement="left" title="Nebula Fighter" arrow>
                  <ButtonWrapper
                    className={theme === 'NebulaFighterTheme' ? 'active' : ''}
                    onClick={() => {
                      changeTheme('NebulaFighterTheme');
                    }}
                  >
                    {theme === 'NebulaFighterTheme' && (
                      <CheckSelected>
                        <CheckTwoToneIcon />
                      </CheckSelected>
                    )}
                    <ColorSchemeWrapper className="colorSchemeWrapper nebulaFighter">
                      <Box className="primary" />
                      <Box className="secondary" />
                    </ColorSchemeWrapper>
                  </ButtonWrapper>
                </Tooltip>
                <Tooltip placement="left" title="Green Fields" arrow>
                  <ButtonWrapper
                    className={theme === 'GreenFieldsTheme' ? 'active' : ''}
                    onClick={() => {
                      changeTheme('GreenFieldsTheme');
                    }}
                  >
                    {theme === 'GreenFieldsTheme' && (
                      <CheckSelected>
                        <CheckTwoToneIcon />
                      </CheckSelected>
                    )}
                    <ColorSchemeWrapper className="colorSchemeWrapper greenFields">
                      <Box className="primary" />
                      <Box className="secondary" />
                    </ColorSchemeWrapper>
                  </ButtonWrapper>
                </Tooltip>
                <Tooltip placement="left" title="Dark Spaces" arrow>
                  <ButtonWrapper
                    className={theme === 'DarkSpacesTheme' ? 'active' : ''}
                    onClick={() => {
                      changeTheme('DarkSpacesTheme');
                    }}
                  >
                    {theme === 'DarkSpacesTheme' && (
                      <CheckSelected>
                        <CheckTwoToneIcon />
                      </CheckSelected>
                    )}
                    <ColorSchemeWrapper className="colorSchemeWrapper darkSpaces">
                      <Box className="primary" />
                      <Box className="secondary" />
                    </ColorSchemeWrapper>
                  </ButtonWrapper>
                </Tooltip>
              </Stack>
            </ThemeToggleWrapper>
          </Stack>
        </Popover>
      </Box>
    </>
  );
};

export default ThemeSettings;
