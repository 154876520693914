import { Box, styled, Typography } from '@mui/material';

/**
 * @package
 */
export const Content = styled(Box)(
  () => `
    display: flex;
    width: 100%;
    height: 100vh;
`
);

/**
 * @package
 */
export const MainContent = styled(Box)(
  ({ theme }) => `
  @media (min-width: ${theme.breakpoints.values.md}px) {
    padding: 0 0 0 0;
    display: flex;
    flex-grow: 1;
  }
  
  margin: auto;
`
);

/**
 * @package
 */
export const SidebarWrapper = styled(Box)(
  ({ theme }) => `
    left: 0;
    top: 0;
    height: 100%;
    background: ${theme.colors.alpha.white[100]};
    width: 440px;
`
);

/**
 * @package
 */
export const SidebarContent = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing(6)};
`
);

/**
 * @package
 */
export const TypographyH1 = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(33)};
`
);
