import { BACKEND_API, getUrl } from 'helpers/http';

export const getPublicPageContent = (tenantId: string, pageId: string) => {
  return getUrl(BACKEND_API.PRISMA_PUBLIC, `/${tenantId}/siteContent/page/${pageId}`);
};

export const getPublicPageList = (tenantId: string) => {
  return getUrl(BACKEND_API.PRISMA_PUBLIC, `/${tenantId}/siteContent/pages`);
};

export const getPublicSiteConfig = (tenantId: string) => {
  return getUrl(BACKEND_API.PRISMA_PUBLIC, `/${tenantId}/siteContent/config`);
};
