import { Alert, styled } from '@mui/material';
import { useContext } from 'react';
import { EnvironmentContext } from 'contexts/EnvironmentContext';

const BannerWrapper = styled('div')(
  ({ theme }) => `
  margin-top:  ${theme.spacing(6)};
  padding: 0 ${theme.spacing(2)};
  margin-bottom: -84px;
  position: relative;
`
);

const WarningAlert = styled(Alert)(
  ({ theme }) => `
  max-width: 800px;
  margin: auto;
`
);

const LateBanner = () => {
  const { tenantName } = useContext(EnvironmentContext);
  return (
    <BannerWrapper>
      <WarningAlert severity="warning" variant="filled" style={{ zIndex: 2 }}>
        <strong>Important notice for our valued users</strong>
        <p>
          To our esteemed job seekers and valued clients, we are committed to providing a seamless and effective hiring
          platform that supports your career and recruitment needs. In order to maintain the high quality of services
          and uninterrupted access, timely payments by our clients are essential.
        </p>

        <p>
          We've noticed that {tenantName} is currently behind on their subscription payment. We understand that
          situations can arise, and we're here to support our clients through them. However, to ensure the continued
          availability of our platform for all users, it is crucial that outstanding payments are settled.
        </p>

        <p>
          Please note: If the pending subscription fee is not cleared within the next 7 days, access to our platform's
          services may be temporarily restricted. This measure is not one we take lightly, but it is necessary to
          preserve the integrity and sustainability of our services.
        </p>

        <p>
          To our job-seeking community, we value your understanding and patience should there be any disruption. Rest
          assured, we are actively working to resolve this matter swiftly.
        </p>

        <p>
          <strong>{tenantName}</strong>, we encourage you to contact our support team at your earliest convenience to
          discuss any issues you may be facing or to arrange for payment. We're here to help and ensure that your access
          to our platform continues without interruption.
        </p>

        <strong>
          Let's work together to keep our platform a thriving ecosystem for job seekers and employers alike. Thank you
          for your attention and cooperation.
        </strong>
      </WarningAlert>
    </BannerWrapper>
  );
};

export default LateBanner;
