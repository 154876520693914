import { createContext, useState } from 'react';

type SidebarContext = {
  sidebarToggle: any;
  fullSidebar: any;
  toggleSidebar: () => void;
  closeSidebar: () => void;
  minimiseSidebar: () => void;
  expandSidebar: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SidebarContext = createContext<SidebarContext>({} as SidebarContext);

export const SidebarProvider = ({ children }) => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [fullSidebar, setFullSidebar] = useState(true);

  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const closeSidebar = () => {
    setSidebarToggle(false);
  };
  const minimiseSidebar = () => {
    setFullSidebar(false);
  };
  const expandSidebar = () => {
    setFullSidebar(true);
  };

  return (
    <SidebarContext.Provider
      value={{
        sidebarToggle,
        fullSidebar,
        toggleSidebar,
        closeSidebar,
        minimiseSidebar,
        expandSidebar
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
