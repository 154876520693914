import { Box, LinearProgress, styled, Tooltip } from '@mui/material';
import { useContext } from 'react';
import { ApplicationProgressContext } from 'contexts/ApplicationProgress';

const ProgressOuterContainer = styled(Box)(
  ({ theme }) => `
    width: 100%;
    height: 10px; 
      
    ${
      theme.palette.mode === 'light'
        ? `background: linear-gradient(90deg, rgba(85,105,255,1) 0%, rgba(85,105,255,1) 50%, rgba(190,198,255,1) 50%, rgba(190,198,255,1) 100%);`
        : `background: rgb(140,124,240);
background: linear-gradient(90deg, rgba(140,124,240,1) 0%, rgba(140,124,240,1) 50%, rgba(70,62,120,1) 50%, rgba(70,62,120,1) 100%);`
    }
  `
);

const ProgressInnerContainer = styled(Box)(
  ({ theme }) => `
    margin-left: auto;
    margin-right: auto;
  `
);

const FinishIcon = styled(Box)(
  ({ theme }) => `
    background-image: linear-gradient(45deg, #000 25%, transparent 25%), linear-gradient(-45deg, #000 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #000 75%), linear-gradient(-45deg, transparent 75%, #000 75%);
    background-color: white;
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
    height: 24px;
    width: 24px;
    display: absolute;
    position: relative;
    float: right;
    border-radius: 24px;
    border: 2px solid ${theme.colors.alpha.black[100]};
    margin-top: -17px;
    margin-right: -9px;
    z-index: 11;
    
    @media only screen and (max-width: 960px) {
      display: none;
    }
  `
);

const FinishTooltip = styled(Tooltip)(
  ({ theme }) => `
  
  `
);

const StyledProgress = styled(LinearProgress)(
  ({ theme }) => `
  height: 10px;
  border-radius:  0;
`
);

export const HeaderProgress = () => {
  const { progressPercent } = useContext(ApplicationProgressContext);

  return (
    <ProgressOuterContainer>
      <ProgressInnerContainer maxWidth="md">
        <StyledProgress variant="determinate" value={progressPercent} />
        <FinishTooltip placement="left" title="Finish" arrow>
          <FinishIcon />
        </FinishTooltip>
      </ProgressInnerContainer>
    </ProgressOuterContainer>
  );
};
