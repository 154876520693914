import { Box, Typography, List, ListItem, ListItemText, styled } from '@mui/material';
import Scrollbar from 'components/layout/Scrollbar';
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone';
import { NavLink as RouterLink } from 'react-router-dom';

const BASE_DOCS_URL = `/app/docs`;

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
  padding-top: ${theme.spacing(10)};
  width: ${theme.sidebar.width};
  color: ${theme.sidebar.textColor};
  background: ${theme.colors.alpha.white[100]};
  box-shadow: ${theme.sidebar.boxShadow};
  height: 100%;
  position: relative;
  z-index: 5;
`
);

const ListWrapper = styled(List)(
  ({ theme }) => `
  .MuiListItem-button {
    margin: ${theme.spacing(0.5)} 0;
    color: ${theme.colors.alpha.black[70]};

    &:first-of-type {
      margin-top: 0;
    }

    &:hover {
      background-color: ${theme.colors.primary.lighter};
      color: ${theme.colors.primary.main};
    }
    
    &.Mui-selected {
      color: ${theme.colors.alpha.black[100]};
    }
  }
`
);

const links = [
  { id: '1', title: 'Welcome', url: 'welcome' },
  { id: '2', title: 'User Management', url: 'user-management' },
  { id: '3', title: 'Auditing', url: 'auditing' },
  { id: '4', title: 'Languages', url: 'languages' },
  { id: '5', title: 'Customisation', url: 'customisation' },
  { id: '6', title: 'Support', url: 'support' }
];

function Sidebar() {
  return (
    <SidebarWrapper>
      <Scrollbar>
        <Typography
          component="div"
          fontWeight="bold"
          color="text.primary"
          sx={{
            pt: 2,
            px: 2
          }}
          variant="caption"
        >
          User Guide
        </Typography>
        <ListWrapper
          sx={{
            p: 2
          }}
        >
          {links.map((link) => (
            <ListItem
              button
              sx={{
                pr: 0.5
              }}
              key={link.id}
              component={RouterLink}
              to={`${BASE_DOCS_URL}/${link.url}`}
            >
              <ListItemText primaryTypographyProps={{ variant: 'h4' }} primary={link.title} />
              <ChevronRightTwoToneIcon />
            </ListItem>
          ))}
        </ListWrapper>
      </Scrollbar>
    </SidebarWrapper>
  );
}

export default Sidebar;
