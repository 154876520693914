import { Box, Card, Container, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import LoginForm from './Form';

import { useTranslation } from 'react-i18next';
import { Content, MainContent } from './Login.styles';
import { useContext } from 'react';
import { EnvironmentContext, EnvironmentName } from 'contexts/EnvironmentContext';
import DemoSidebar from 'pages/util/Auth/Login/DemoSidebar';
import DemoInfoBox from 'pages/util/Auth/Login/DemoInfoBox';

function Login() {
  const { t }: { t: any } = useTranslation();
  const { environmentName } = useContext(EnvironmentContext);

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Content>
        {environmentName === EnvironmentName.DEMO && <DemoSidebar />}
        <MainContent>
          <Container
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              maxWidth: '540px',
              p: 0
            }}
          >
            {environmentName === EnvironmentName.DEMO && <DemoInfoBox />}
            <Card sx={{ p: 4, my: 4, mx: 2 }}>
              <Box textAlign="center">
                <Typography variant="h2" sx={{ mb: 1 }}>
                  {t('Sign in')}
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  fontWeight="normal"
                  sx={{
                    mb: 3
                  }}
                >
                  {t('Fill in the fields below to sign into your account.')}
                </Typography>
              </Box>
              <LoginForm />
            </Card>
          </Container>
        </MainContent>
      </Content>
    </>
  );
}

export default Login;
