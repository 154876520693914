import { Box, Button, Card, Container, Drawer, IconButton, Link, Skeleton, styled, useTheme } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { getPublicPageList } from 'api/public/jobSiteContent';
import useRefMounted from 'hooks/useRefMounted';
import { JobSitePageListItem } from 'types/shared/api/jobSiteContent';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import Scrollbar from 'components/layout/Scrollbar';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import DarkModeSwitch from 'components/widgets/DarkModeSwitch';
import { EnvironmentContext, EnvironmentName } from 'contexts/EnvironmentContext';

const HeaderWrapper = styled(Card)(
  ({ theme }) => `
    width: 100%;
    display: flex;
    align-items: center;
    height: ${theme.spacing(10)};
    z-index: 10;
    position: fixed;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
`
);

const IconButtonToggle = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(6)};
  height: ${theme.spacing(6)};
  background: ${theme.colors.alpha.white[100]};
  border: 1px solid ${theme.colors.alpha.black[30]};
`
);

const DrawerWrapperMobile = styled(Drawer)(
  () => `
    width: 340px;
    flex-shrink: 0;

  & > .MuiPaper-root {
        width: 340px;
        z-index: 3;
  }
`
);

const HeaderLink = styled(Link)(
  ({ theme }) => `
    color: ${theme.colors.alpha.black[70]};
    margin-right: 2rem;
    
    &:hover {
      cursor: pointer;
      text-decoration: none;
      color: ${theme.colors.alpha.black[100]};
    }
    
    &.highlighted {
      font-weight: bold;
      color: ${theme.palette.primary.main};
    }
  `
);

const HeaderLinkSkeleton = styled(Skeleton)(
  ({ theme }) => `
    margin-right: 2rem;
    width: 100px;
    display: inline-block;
    
    @media (max-width: ${theme.breakpoints.values.sm}px) {
      display: none;
    }
  `
);

const SidebarLogoContainer = styled(Box)(
  ({ theme }) => `
    display: flex;
    padding: ${theme.spacing(2)};
    border-bottom: 1px solid ${theme.colors.alpha.black[30]};
    justify-content: center;
  `
);

const SidebarLink = styled(Link)(
  ({ theme }) => `
    width: 100%;
    border-bottom: 1px solid ${theme.colors.alpha.black[30]};
    display: block;
    
    padding: ${theme.spacing(2)};
    
    color: ${theme.colors.alpha.black[70]};
    margin-right: 2rem;
    
    &:hover {
      cursor: pointer;
      text-decoration: none;
      color: ${theme.colors.alpha.black[100]};
    }
    
    &.highlighted {
      font-weight: bold;
      color: ${theme.palette.primary.main};
    }
  `
);

const HeaderLinks = styled(Box)(
  ({ theme }) => `
    margin-left: 2rem;
  `
);

const HeaderLogo = styled('img')(
  ({ theme }) => `
    max-width: 140px;
    
    &:hover {
      cursor: pointer;
    }
  `
);

const LogoContainer = styled(Link)(
  ({ theme }) => `
    border-radius: ${theme.general.borderRadius};
    padding: ${theme.spacing(1)} ${theme.spacing(2)};
    background-color: ${
      theme.palette.mode === 'dark' ? theme.colors.alpha.trueWhite[30] : theme.colors.alpha.white[100]
    }
  `
);

const determinePagesToShow = (pages: JobSitePageListItem[]): JobSitePageListItem[] => {
  return pages.filter((page) => page.displayOnHeaderNav === true);
};

const permanentPages: JobSitePageListItem[] = [
  {
    title: 'Positions',
    path: 'positions',
    displayOnHeaderNav: true
  },
  {
    title: 'Teams',
    path: 'teams',
    displayOnHeaderNav: true
  }
];

export const Header = () => {
  const isMountedRef = useRefMounted();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [pages, setPages] = useState<JobSitePageListItem[]>(undefined);
  const [mobileOpen, setMobileOpen] = useState(false);
  const { environmentName, tenantId, publicTenantConfig } = useContext(EnvironmentContext);

  const fetchData = useCallback(async () => {
    try {
      const { data: pages } = await getPublicPageList(tenantId);
      if (isMountedRef.current) {
        let filteredPages = determinePagesToShow(pages as JobSitePageListItem[]);
        filteredPages = [...permanentPages, ...filteredPages];
        setPages(filteredPages);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  const isCurrentRoute = (pathName: string) => {
    return location.pathname.includes(pathName);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <HeaderWrapper>
        <Container maxWidth={'xl'}>
          <Box display="flex" alignItems="center">
            <LogoContainer
              onClick={() => navigate('/')}
              role="link"
              style={{ textDecoration: 'none' }}
              ml={{ xs: '-5px', sm: '-15px' }}
            >
              {publicTenantConfig ? (
                <HeaderLogo
                  // Prefer the use of SVG, but fall back to PNG if needed
                  src={
                    publicTenantConfig.companyDetails.logos.svg
                      ? publicTenantConfig.companyDetails.logos.svg
                      : publicTenantConfig.companyDetails.logos.png
                  }
                  alt={`${publicTenantConfig.companyDetails.name} company logo`}
                />
              ) : (
                <Skeleton variant="rectangular" sx={{ width: '140px', height: '50px' }} />
              )}
            </LogoContainer>
            <Box display="flex" alignItems="center" justifyContent="space-between" flex={1}>
              <HeaderLinks role="navigate">
                {pages ? (
                  <>
                    {pages.map((page, index) => (
                      <HeaderLink
                        key={index}
                        sx={{
                          display: { xs: 'none', md: 'inline-block' }
                        }}
                        onClick={() => navigate(`../${page.path}`)}
                        role="link"
                        className={isCurrentRoute(page.path) ? 'highlighted' : ''}
                      >
                        {page.title}
                      </HeaderLink>
                    ))}
                  </>
                ) : (
                  <>
                    <HeaderLinkSkeleton />
                    <HeaderLinkSkeleton />
                    <HeaderLinkSkeleton />
                    <HeaderLinkSkeleton />
                  </>
                )}
              </HeaderLinks>
              <Box>
                {environmentName === EnvironmentName.DEMO && (
                  <Box sx={{ display: { xs: 'none', lg: 'inline-block' }, marginRight: '10px' }}>
                    <Button component={RouterLink} to="/app/applications" size="large" variant="contained">
                      Hiring Dashboard
                    </Button>
                  </Box>
                )}
                <Box sx={{ display: { xs: 'none', md: 'inline-block' } }}>
                  <DarkModeSwitch />
                </Box>
                <IconButtonToggle
                  sx={{
                    display: { md: 'none', xs: 'inline-block' }
                  }}
                  color="primary"
                  onClick={handleDrawerToggle}
                  size="small"
                >
                  <MenuTwoToneIcon />
                </IconButtonToggle>
              </Box>
            </Box>
          </Box>
        </Container>
      </HeaderWrapper>
      <DrawerWrapperMobile
        sx={{
          display: { lg: 'none', xs: 'inline-block' }
        }}
        variant="temporary"
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={mobileOpen}
        onClose={handleDrawerToggle}
      >
        <Scrollbar>
          <SidebarLogoContainer>
            <Link onClick={() => navigate('/')} role="link" style={{ textDecoration: 'none' }}>
              {publicTenantConfig && (
                <HeaderLogo
                  // Prefer the use of SVG, but fall back to PNG if needed
                  src={
                    publicTenantConfig.companyDetails.logos.svg
                      ? publicTenantConfig.companyDetails.logos.svg
                      : publicTenantConfig.companyDetails.logos.png
                  }
                  alt={`${publicTenantConfig.companyDetails.name} company logo`}
                />
              )}
            </Link>
          </SidebarLogoContainer>
          {pages &&
            pages.map((page, index) => (
              <SidebarLink
                key={index}
                onClick={() => {
                  navigate(`../${page.path}`);
                  setMobileOpen(false);
                }}
                role="link"
                className={isCurrentRoute(page.path) ? 'highlighted' : ''}
              >
                {page.title}
              </SidebarLink>
            ))}
        </Scrollbar>
      </DrawerWrapperMobile>
    </>
  );
};
