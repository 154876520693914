// Permission mappings are defined in lambda/src/_helpers/permissions/config

export enum RoleType {
  SUPER_USER = 'superuser',
  ADMIN = 'admin',
  ADMIN_DEMO = 'admindemo',
  STANDARD = 'standard',
  READ_ONLY = 'readonly',
  DEACTIVATED = 'deactivated'
}

export type RoleLabel =
  | 'Super User'
  | 'Administrator'
  | 'Administrator (Demo)'
  | 'Standard'
  | 'Read only'
  | 'Deactivated';

export interface RoleLabelMapping {
  value: RoleType | string;
  label: RoleLabel | string;
}

// This interface is used in the two below types.
interface UserRoleBase {
  // Unique ID of the role
  id: RoleType | string;
  // Name of the role displayed in the app
  displayName: string;
  // Description of the role displayed in the app
  description: string;
  // Users with a lower level cannot manage users with a higher level, or grant higher permissions than they have.
  hierarchy: number;
}

// We don't expose the system level to the front-end, so hide it.
export type UserRole = Omit<UserRoleBase, 'hierarchy'>;

export type UserRoleConfig = Omit<UserRoleBase, 'id'>;

// Specific actions which can be performed against a given product feature.
//
// VIEW   - See a specific resource
// LIST   - View a list of resources
// CREATE - Create a new resource
// UPDATE - Update an existing resource
// DELETE - Delete the resource
// EMAIL  - Trigger an email to be sent
// EXPORT - Withdraw data from the resource
// TEST   - (Internal use only) Test endpoint
export type FeatureAction = 'VIEW' | 'LIST' | 'CREATE' | 'UPDATE' | 'DELETE' | 'EMAIL' | 'EXPORT' | 'TEST';

// Features which can have permissions against them
//
// AUDIT_LOG                - Logs for seeing a history of user activity
// JOB_POSITION             - Managing job position listings on the public site
// JOB_CATEGORY             - Managing the categories job positions fit into
// JOB_APPLICATION_STATUS   - Managing specific user job applications
// JOB_APPLICATION_APPROVAL - Approving or denying job applications
// JOB_SITE_CONTENT         - Managing the content on the job site
// DASHBOARD                - The default dashboard when a user logs in.
// SUPPORT                  - Allows users to raise support requests.
// BASE_MANAGEMENT          - The management section on the sidebar. User must have this permission before
//                            being granted any other management permissions. This permission doesn't grant anything by itself
// WORKSPACE_MANAGEMENT     - Workspace instance wide settings. Having this permission allows the user
//                            to change config which affects the application as a whole, i.e logos, themes.
// USER_MANAGEMENT          - This feature allows the user to manage other users.
// DEVELOPMENT              - Dev tools
// FILE_MANAGEMENT_PUBLIC   - Uploading and deleting files which affect the public site
// FILE_MANAGEMENT_PRIVATE  - Uploading and deleting internal files
export type Feature =
  | 'AUDIT_LOG'
  | 'JOB_POSITION'
  | 'JOB_CATEGORY'
  | 'JOB_APPLICATION_STATUS'
  | 'JOB_APPLICATION_APPROVAL'
  | 'JOB_SITE_CONTENT'
  | 'HIRING_WORKFLOW'
  | 'DASHBOARD'
  | 'BILLING'
  | 'SUPPORT'
  | 'BASE_MANAGEMENT'
  | 'WORKSPACE_MANAGEMENT'
  | 'FILE_MANAGEMENT_PUBLIC'
  | 'FILE_MANAGEMENT_PRIVATE'
  | 'DEVELOPMENT'
  | 'USER_MANAGEMENT';

// Mapping of features to the allowed actions against those features.
export type PermissionSet = Record<Feature, FeatureAction[]>;

// Represents one permission which a user either has, or hasn't.
export interface Permission {
  // The feature the user is accessing. Can also be thought of as a resource.
  feature: Feature;
  // The action the user is performing against the feature.
  action: FeatureAction;
}

// Default case where the user has no permissions. This will need to be updated each time
// a new feature is added.
export const NO_PERMISSIONS: PermissionSet = {
  DASHBOARD: [],
  AUDIT_LOG: [],
  SUPPORT: [],
  JOB_POSITION: [],
  JOB_CATEGORY: [],
  JOB_APPLICATION_STATUS: [],
  JOB_APPLICATION_APPROVAL: [],
  JOB_SITE_CONTENT: [],
  BASE_MANAGEMENT: [],
  USER_MANAGEMENT: [],
  HIRING_WORKFLOW: [],
  WORKSPACE_MANAGEMENT: [],
  FILE_MANAGEMENT_PUBLIC: [],
  FILE_MANAGEMENT_PRIVATE: [],
  DEVELOPMENT: [],
  BILLING: []
};
