import { Box, List, Divider, styled } from '@mui/material';
import { useLocation, matchPath } from 'react-router-dom';
import menuItems, { MenuItem } from 'layouts/SidebarLayout/items';
import SidebarMenuItem from './item';
import { v4 as uuidv4 } from 'uuid';
import { Fragment } from 'react';
import { DividerWrapper, MenuWrapper, SubMenuWrapper } from './index.styles';

const renderSidebarMenuItems = ({
  items,
  path
}: {
  items: MenuItem[];
  path: string;
}): JSX.Element => (
  <SubMenuWrapper>
    <List component="div">
      {items.reduce((ev, item) => reduceChildRoutes({ ev, item, path }), [])}
    </List>
  </SubMenuWrapper>
);

const reduceChildRoutes = ({
  ev,
  path,
  item
}: {
  ev: JSX.Element[];
  path: string;
  item: MenuItem;
}): Array<JSX.Element> => {
  const key = uuidv4();

  const exactMatch = item.link
    ? !!matchPath(
        {
          path: item.link,
          end: true
        },
        path
      )
    : false;

  if (item.items) {
    const partialMatch = item.link
      ? !!matchPath(
          {
            path: item.link,
            end: false
          },
          path
        )
      : false;

    ev.push(
      <SidebarMenuItem
        key={key}
        active={partialMatch}
        open={partialMatch}
        name={item.name}
        icon={item.icon}
        link={item.link}
        badge={item.badge}
      >
        {renderSidebarMenuItems({
          path,
          items: item.items
        })}
      </SidebarMenuItem>
    );
  } else {
    ev.push(
      <SidebarMenuItem
        key={key}
        active={exactMatch}
        name={item.name}
        link={item.link}
        badge={item.badge}
        icon={item.icon}
      />
    );
  }

  return ev;
};

function SidebarMenu() {
  const location = useLocation();

  return (
    <>
      {menuItems.map((section) => (
        <Fragment key={uuidv4()}>
          <MenuWrapper>
            <List component="div">
              {renderSidebarMenuItems({
                items: section.items,
                path: location.pathname
              })}
            </List>
          </MenuWrapper>
          <DividerWrapper />
        </Fragment>
      ))}
    </>
  );
}

export default SidebarMenu;
