import { Box, Card, Link, styled, Typography } from '@mui/material';

const FooterWrapper = styled(Card)(
  ({ theme }) => `
        border-radius: 0;
        margin-top: ${theme.spacing(4)};
`
);

function Footer() {
  return (
    <FooterWrapper className="footer-wrapper">
      <Box
        p={2}
        display={{ xs: 'block', md: 'flex' }}
        alignItems="center"
        textAlign={{ xs: 'center', md: 'left' }}
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="subtitle1">&copy; {new Date().getFullYear()} - Prisma</Typography>
        </Box>
        <Typography
          sx={{
            pt: { xs: 2, md: 0 }
          }}
          variant="subtitle1"
        >
          {' '}
          Powered by{' '}
          <Link href="https://openresume.info" target="_blank" rel="noopener noreferrer">
            OpenResume
          </Link>
        </Typography>
      </Box>
    </FooterWrapper>
  );
}

export default Footer;
