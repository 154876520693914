import { useContext } from 'react';
import Scrollbar from 'components/layout/Scrollbar';
import { SidebarContext } from 'contexts/SidebarContext';
import Logo from 'components/common/LogoSign';

import { Box, darken, Divider, Drawer, styled, useTheme } from '@mui/material';

import SidebarMenu from './SidebarMenu';
import SidebarFooter from './SidebarFooter';
import { useTranslation } from 'react-i18next';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.spacing(12)};
        color: ${theme.colors.alpha.trueWhite[70]};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        height: 100%;
        position: relative;
        z-index: 7;
        height: 100%;
        padding-bottom: 61px;
        
        @media (min-width: ${theme.breakpoints.values.md}px) {
          top: 0;
          left: 0;
          position: fixed;
          z-index: 10;
        }
`
);

const TopSection = styled(Box)(
  ({ theme }) => `
        display: flex;
        height: 80px;
        align-items: center;
        margin: ${theme.spacing(0, 2)};
        border-bottom: ${theme.sidebar.dividerBg} solid 1px;
`
);

function SidebarMin() {
  const { sidebarToggle, toggleSidebar, minimiseSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  const sidebarContent = (
    <>
      <Scrollbar>
        <TopSection>
          <Logo small />
        </TopSection>
        <SidebarMenu />
      </Scrollbar>
      <Divider
        sx={{
          background: theme.colors.alpha.trueWhite[10]
        }}
      />
      <SidebarFooter />
    </>
  );

  return (
    <>
      {/* Fixed content - only appears on larger screens */}
      <SidebarWrapper
        sx={{
          display: { xs: 'none', md: 'inline-block' },
          background:
            theme.palette.mode === 'dark' ? theme.colors.alpha.white[100] : darken(theme.colors.alpha.black[100], 0.5)
        }}
      >
        {sidebarContent}
      </SidebarWrapper>

      {/* Drawer content - only appears in small mode, and appears as a drawer */}
      <Drawer
        sx={{
          display: { md: 'none', xs: 'inline-block' },
          background:
            theme.palette.mode === 'dark' ? theme.colors.alpha.white[100] : darken(theme.colors.alpha.black[100], 0.5)
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper>{sidebarContent}</SidebarWrapper>
      </Drawer>
    </>
  );
}

export default SidebarMin;
