import DemoText from 'pages/util/Auth/Login/DemoText';
import { Card } from '@mui/material';

const DemoInfoBox = () => {
  return (
    // Minor bug: I was lazy and put margin top 28 here. Should fix at some point.
    <Card sx={{ display: { xs: 'inline-block', md: 'none' }, p: 4, mt: 4, mx: 2 }}>
      <DemoText />
    </Card>
  );
};

export default DemoInfoBox;
