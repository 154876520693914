import axios, { AxiosRequestConfig } from 'axios';
import { logError } from 'helpers/log';
import { getAuthToken } from './libraries/cognito';

export enum BACKEND_API {
  OPENRESUME = 'openresume',
  PRISMA = 'prisma',
  PRISMA_PUBLIC = 'primsa-public',
  PRISMA_BOOT = 'prisma'
}

const determineAPIEndpoint = (apiType: BACKEND_API): string => {
  switch (apiType) {
    case BACKEND_API.OPENRESUME:
      return process.env.REACT_APP_OPENRESUME_API_BASE_URL ?? '';
    case BACKEND_API.PRISMA || BACKEND_API.PRISMA_BOOT:
      return process.env.REACT_APP_PRISMA_API_BASE_URL ?? '';
    case BACKEND_API.PRISMA_PUBLIC:
      return `${process.env.REACT_APP_PRISMA_API_BASE_URL}/public` ?? '';
    default:
      throw new Error('Unknown API');
  }
};

export const getUrl = async (backendAPI: BACKEND_API, path: string) => {
  const endpoint = `${determineAPIEndpoint(backendAPI)}${path}`;
  try {
    const config: AxiosRequestConfig = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const authToken = await getAuthToken();
    if (authToken && typeof authToken === 'string') {
      config.headers['Authorization'] = `Bearer ${authToken}`;
    }
    return await axios(endpoint, config);
  } catch (err: any) {
    const message = `Unable to retrieve data from ${endpoint}`;
    logError(err);
    throw Error(message);
  }
};

export const postUrl = async (backendAPI: BACKEND_API, path: string, body: any, multipart?: boolean) => {
  const endpoint = `${determineAPIEndpoint(backendAPI)}${path}`;
  try {
    const config: AxiosRequestConfig = {
      method: 'POST',
      data: multipart ? body : JSON.stringify(body),
      headers: multipart
        ? {}
        : {
            'Content-Type': 'application/json'
          }
    };
    const authToken = await getAuthToken();
    if (authToken && typeof authToken === 'string') {
      config.headers['Authorization'] = `Bearer ${authToken}`;
    }
    return await axios(endpoint, config);
  } catch (err: any) {
    const message = `Unable to send data to ${endpoint}`;
    logError(err);
    throw Error(message);
  }
};

export const patchUrl = async (backendAPI: BACKEND_API, path: string, body: any) => {
  const endpoint = `${determineAPIEndpoint(backendAPI)}${path}`;
  const data = JSON.stringify(body);
  try {
    const config: AxiosRequestConfig = {
      method: 'PATCH',
      data,
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const authToken = await getAuthToken();
    if (authToken) {
      config.headers['Authorization'] = `Bearer ${authToken}`;
    }
    return await axios(endpoint, config);
  } catch (err: any) {
    const message = `Unable to send update data at ${endpoint}`;
    logError(err);
    throw Error(message);
  }
};

export const deleteUrl = async (backendAPI: BACKEND_API, path: string, body?: any) => {
  const endpoint = `${determineAPIEndpoint(backendAPI)}${path}`;
  const data = JSON.stringify(body);
  try {
    const config: AxiosRequestConfig = {
      method: 'DELETE',
      data,
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const authToken = await getAuthToken();
    if (authToken) {
      config.headers['Authorization'] = `Bearer ${authToken}`;
    }
    return await axios(endpoint, config);
  } catch (err: any) {
    const message = `Unable to delete data at ${endpoint}`;
    logError(err);
    throw Error(message);
  }
};

export const putUrl = async (backendAPI: BACKEND_API, path: string, body: any) => {
  const endpoint = `${determineAPIEndpoint(backendAPI)}${path}`;
  const data = JSON.stringify(body);
  try {
    const config: AxiosRequestConfig = {
      method: 'PUT',
      data,
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const authToken = await getAuthToken();
    if (authToken) {
      config.headers['Authorization'] = `Bearer ${authToken}`;
    }
    return await axios(endpoint, config);
  } catch (err: any) {
    const message = `Unable to replace data at ${endpoint}`;
    logError(err);
    throw Error(message);
  }
};
