import { alpha, Box, IconButton, styled, Tooltip, tooltipClasses, TooltipProps, useTheme } from '@mui/material';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { useContext } from 'react';
import { SidebarContext } from 'contexts/SidebarContext';
import { useTranslation } from 'react-i18next';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    boxShadow: theme.shadows[24],
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(12)
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100]
  }
}));

const SidebarFooter = () => {
  const { t }: { t: any } = useTranslation();
  const { expandSidebar } = useContext(SidebarContext);
  const theme = useTheme();

  return (
    <Box sx={{
      height: '60px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <LightTooltip placement="top" arrow title={t('Expand Sidebar')}>
        <IconButton
          sx={{
            background: `${theme.colors.alpha.trueWhite[10]}`,
            color: `${theme.colors.alpha.trueWhite[70]}`,
            transition: `${theme.transitions.create(['all'])}`,

            '&:hover': {
              background: `${alpha(theme.colors.alpha.trueWhite[100], 0.2)}`,
              color: `${theme.colors.alpha.trueWhite[100]}`
            },
            mx: 1
          }}
          onClick={expandSidebar}
        >
          <OpenInFullIcon fontSize="small" />
        </IconButton>
      </LightTooltip>
    </Box>
  );
}

export default SidebarFooter;